import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import {
  LOADING,
  GET_DISCUSSION_LIST,
  TRENDING_DISCUSSION,
  RELATED_DISCUSSION,
  GET_DISCUSSION_BY_ID,
  GET_DISCUSSION,
  GET_DISCUSSION_COMMENT_LIST,
  DISCUSSION_COMMENT_ADD,
  GET_DISCUSSION_CATEGORY_LIST,
  DISCUSSION_LIKE_UNLIKE,
  DISCUSSION_COMMENT_LIKE_UNLIKE,
  ADD_DISCUSSION,
} from "../common/constant";

const loading = (data: any) => {
  return { type: LOADING, payload: data };
};
const getTrendingDiscussionListAction = (data: any) => {
  return { type: TRENDING_DISCUSSION, payload: data };
};
const getRelatedDiscussionListAction = (data: any) => {
  return { type: RELATED_DISCUSSION, payload: data };
};
const getDiscussionListAction = (data: any) => {
  return { type: GET_DISCUSSION_LIST, payload: data };
};
const getDiscussionsbyIdAction = (data: any) => {
  return { type: GET_DISCUSSION_BY_ID, payload: data };
};
const getDiscussionAction = (data: any) => {
  return { type: GET_DISCUSSION, payload: data };
};
const getdiscommentlistAction = (data: any) => {
  return { type: GET_DISCUSSION_COMMENT_LIST, payload: data };
};
const discommentAddAction = (data: any) => {
  return { type: DISCUSSION_COMMENT_ADD, payload: data };
};
const getDisCategoryListAction = (data: any) => {
  return { type: GET_DISCUSSION_CATEGORY_LIST, payload: data };
};
const discussionLikeUnlikeAction = (data: any) => {
  return { type: DISCUSSION_LIKE_UNLIKE, payload: data };
};
const CommentLikeUnlikeAction = (data: any) => {
  return { type: DISCUSSION_COMMENT_LIKE_UNLIKE, payload: data };
};
const addDiscussionAction = (data: any) => {
  return { type: ADD_DISCUSSION, payload: data };
};
export const getTrendingDiscussion = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(`${URL.trendingDiscussion}`);
      dispatch(getTrendingDiscussionListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getRelatedDiscussion = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(
        `${URL.relatedDiscussion}?topic=${data}`
      );
      dispatch(getRelatedDiscussionListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getDiscussionList = (data: any) => {
  return async (dispatch: any) => {
    try {
      /* dispatch(loading(true)); */
      const response: any = await api.get(`${URL.getDiscussionList}`, {
        params: data,
      });
      dispatch(getDiscussionListAction(response?.data?.data));
      /* dispatch(loading(false)); */
    } catch (error: any) {
      /* dispatch(loading(false)); */
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getDiscussionsbyId = (data: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response: any = await api.post(
        `${URL.getDiscussionsbyId}${"/" + data}`
      );
      dispatch(getDiscussionsbyIdAction(response?.data));
      // dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getDiscussion = (data: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response: any = await api.post(`${URL.getDiscussion}`, data);
      dispatch(getDiscussionAction(response?.data?.data));
      // dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const addDiscussion = (data: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response: any = await api.post(`${URL.addDiscussion}`, data);
      dispatch(addDiscussionAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

// Discussion comment

export const getdiscommentlist = (data: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response: any = await api.post(`${URL.getdiscommentlist}`, data);
      dispatch(getdiscommentlistAction(response?.data?.data));
      // dispatch(loading(false));
    } catch (error: any) {
      // dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const discommentAdd = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.post(`${URL.discommentAdd}`, data);
      dispatch(discommentAddAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

// Discussion category

export const getDisCategoryList = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getDisCategoryList}`);
      dispatch(getDisCategoryListAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

/**
 * DIscussion Like Unlike
 */
export const discussionLikeUnlike = (data: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response: any = await api.post(`${URL.discussionLikeUnlike}`, data);
      dispatch(discussionLikeUnlikeAction(response?.data?.data));
      // dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
/**
 * CommentLikeUnlike Like Unlike
 */
export const CommentLikeUnlike = (data: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response: any = await api.post(
        `${URL.discussionCommentLikeUnlike}`,
        data
      );
      dispatch(CommentLikeUnlikeAction(response?.data?.data));
      // if(response?.data?.data){

      // }
      // console.log(response?.data?.data);
      // dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
