import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import { officeAction } from "../../redux/common/action";
import Seo from "../../Component/Seo/Seo";
import SocialShareButton from "../SocialShareButton";
import Breadcrumb from "../Breadcrumb";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";
import ReCAPTCHAComponent from "../Service/ReCAPTCHA";
function ContactUs() {
  const urlsforShare = window.location.href;
  const location = useLocation();
  const history = useHistory();
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const handleRecaptchaChange = (value: any) => {
    setRecaptchaValue(value);
  };

  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);

  const universityCommentup: any[] = useSelector((state: any) =>
    state.office.getInTouch ? state.office.getInTouch : []
  );
  const dispatch = useDispatch();
  const office: any = useSelector((state: any) =>
    state.office.officeList ? state.office.officeList : []
  );
  const [Office, setOffice] = useState<any>();
  const activeLocation =
    office &&
    office?.filter((data: any) => {
      return data?.status == 1;
    });
  useEffect(() => {
    dispatch(officeAction.getofficelist({}));
    return () => {};
  }, []);

  const hendlereviews = (e: any) => {
    const { name, value } = e.target;
    setOffice({ ...Office, [name]: value });
  };
  const addQuery = (e: any) => {
    // if (!recaptchaValue) {
    //   alert("Please complete the reCAPTCHA!");
    //   return;
    // }

    var inputs = $("#" + e).find("input");
    $(".error-class").remove();
    var isError = false;
    var phoneno = /^[1-9]{1}[0-9]{9}$/;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (inputs.length > 0) {
      $.each(inputs, function (k, v) {
        if (!v.value) {
          $(this).after(
            '<div class="error-class">This field is required.</div>'
          );
          isError = true;
        }
      });
      if (inputs[1].value == "") {
        $("#mob").after(
          '<div class="error-class">This field is required.</div>'
        );
        isError = true;
      }
      if (inputs[1].value) {
        if (inputs[1].value.length != 10) {
          $("#mob").after('<div class="error-class">Invalid mobile.</div>');
          isError = true;
        }
      }
      if (inputs[1].value) {
        if (phoneno.test(inputs[1].value) === false) {
          $("#mob").after('<div class="error-class">Invalid mobile.</div>');
          isError = true;
        }
      }
      if (inputs[2].value) {
        if (regex.test(inputs[2].value) === false) {
          $("#email").after('<div class="error-class">Invalid email.</div>');
          isError = true;
        }
      }
    }
    if (isError) {
      return false;
    }
    let dt: any = { ...Office };
    dt.User_id = user?.id ? user?.id : "";
    dispatch(officeAction.getInTouch(dt, history));
  };

  const seo: any = useSelector((state: any) => state.setting.seo);

  return (
    <>
      <Seo slug={window.location.pathname} />
      <div>
        <section className="t-banner">
          <div className="t-banner-img">
            <img src="assets/img/contact-banner.jpg"></img>
            <div className="container">
              <div className="tag-l tag--mob-cn">
                <h1>contact us</h1>
                <p>
                  Get in touch with us quickly by submitting your name, email
                  and message. Call us or write us an email for contacting the
                  executives from Admissify.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="c-add">
          <div className="container">
            <Breadcrumb page={"Contact us"} data={{ slug: "", path: "" }} />
            <div className="row">
              <div className="headingmains text-center ofc_add">
                <div className="d-flex justify-content-end pb-4 mr-3">
                  <SocialShareButton urlsforShare={urlsforShare} data={""} />
                </div>
                <h4 className="titlewithline course-head" id="offices">
                  head offices
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="43.032"
                      height="35.894"
                      viewBox="0 0 43.032 35.894"
                    >
                      <path
                        id="mortarboard"
                        d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z"
                        transform="translate(0 -42.469)"
                        fill="#fbb415"
                      ></path>
                    </svg>
                  </span>
                </h4>
              </div>
              <div className="container">
                {seo?.header_content && (
                  <div className="about-uni-text shade1">
                    <SeoHeaderContent />
                  </div>
                )}
              </div>
              {activeLocation &&
                activeLocation?.map((data: any, i: any) => {
                  if (data?.office_type == 1)
                    return (
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div className="shade1 text-center">
                          <iframe
                            src={data?.location}
                            width="100%"
                            height="200"
                            style={{ border: "0" }}
                            loading="lazy"
                          ></iframe>
                          <div
                            className="add-detail"
                            // style={{ height: "130px", overflowY: "auto" }}
                          >
                            <h1>
                              {/* <Link className="__hover" to={"/location/" + data?.slug} target="_blank">
                                {data?.city_name}
                              </Link> */}
                              {data?.slug ? (
                                <Link
                                  className="__hover"
                                  to={"/location/" + data?.slug}
                                  target="_blank"
                                >
                                  {data?.city_name}
                                </Link>
                              ) : (
                                data?.city_name
                              )}
                            </h1>
                            <p>{data?.address}</p>
                            <div className="c-info">
                              <p>
                                <a href="tel:+9101141219999">
                                  <span className="c-tag">phone: </span>
                                  {data?.phone_number}
                                </a>
                              </p>
                              <p>
                                <a href="tel:+9101141219999">
                                  <span className="c-tag">email: </span>
                                  {data?.email}
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                })}
              <div className="headingmains text-center ofc_add">
                <h4 className="titlewithline course-head">
                  regional offices
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="43.032"
                      height="35.894"
                      viewBox="0 0 43.032 35.894"
                    >
                      <path
                        id="mortarboard"
                        d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z"
                        transform="translate(0 -42.469)"
                        fill="#fbb415"
                      ></path>
                    </svg>
                  </span>
                </h4>
              </div>

              {activeLocation &&
                activeLocation?.map((data: any, i: any) => {
                  if (data?.office_type == 2)
                    return (
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div className="shade1 text-center h-100">
                          <iframe
                            src={data?.location}
                            width="100%"
                            height="200"
                            style={{ border: "0" }}
                            loading="lazy"
                          ></iframe>
                          <div className="add-detail">
                            <h1>
                              {data?.slug ? (
                                <Link
                                  className="__hover"
                                  to={"/location/" + data?.slug}
                                  target="_blank"
                                >
                                  {data?.city_name}
                                </Link>
                              ) : (
                                data?.city_name
                              )}
                            </h1>
                            <p>{data?.address}</p>
                            <div className="c-info">
                              <p>
                                <a href="tel:+9101141219999">
                                  <span className="c-tag">phone: </span>
                                  {data?.phone_number}
                                </a>
                              </p>
                              <p>
                                <a href="tel:+9101141219999">
                                  <span className="c-tag">email: </span>
                                  {data?.email}
                                </a>
                              </p>
                              {/* <p><a href="mailto:info@admissify.com "><span className="c-tag">e-mail: </span>info@admissify.com </a></p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                })}
            </div>
            {seo?.footer_content && (
              <div className="about-uni-text shade1 mt-5">
                <SeoFooterContent />
              </div>
            )}
          </div>
        </section>

        <section className="categories section bg-grey">
          <div className="container">
            <div className="">
              <div className="row g-0 justify-content-center">
                <div className="d-none d-md-flex col-md-6 col-lg-4 bg-image q-box-bg">
                  <div>
                    <h3 className="text-white">send your query</h3>

                    <p className="text-left text-white">
                      we would love to hear from you. send us your message or
                      just say hi, we love to chat.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 rightbx bg-white">
                  <div className="login d-flex align-items-center mt-20">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-11 col-lg-10">
                          <h5 className="get-touch">get in touch</h5>
                        </div>

                        <div className="fields-wrp w-100">
                          <form id="contactUs">
                            <div className="field">
                              <input
                                type="text"
                                name="Name"
                                required
                                value={Office?.Name}
                                onChange={(e) => {
                                  hendlereviews(e);
                                }}
                              />
                              <label>name</label>
                              <span className="fa fa-user p-2"></span>
                            </div>
                            <div className="field">
                              <input
                                type="text"
                                required
                                name="Mobile"
                                maxLength={10}
                                id="mob"
                                value={Office?.Mobile}
                                onChange={(e) => {
                                  hendlereviews(e);
                                }}
                              />
                              <label>mobile</label>
                              <span className="fa fa-phone p-2"></span>
                            </div>
                            <div className="field">
                              <input
                                type="text"
                                required
                                name="Email"
                                id="email"
                                value={Office?.Email}
                                onChange={(e) => {
                                  hendlereviews(e);
                                }}
                              />
                              <label>email</label>
                              <span className="fa fa-envelope p-2"></span>
                            </div>
                            <div className="field">
                              <textarea
                                className="q-msg"
                                rows={4}
                                cols={50}
                                placeholder="message"
                                name="Message"
                                value={Office?.Message}
                                onChange={(e) => {
                                  hendlereviews(e);
                                }}
                              ></textarea>
                            </div>
                            {/* <ReCAPTCHAComponent
                              onRecaptchaChange={handleRecaptchaChange}
                            /> */}
                            <div
                              className="fieldbtn mt-100 mob-mb-25"
                              style={{ marginBottom: "15px" }}
                            >
                              <div
                                onClick={(e) => {
                                  addQuery("contactUs");
                                }}
                                className="bggreadent removeMinHeight btn btn-success  br-5"
                              >
                                send
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default ContactUs;
