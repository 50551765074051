import React from "react";
import "./style.css";
import alarm from "../../assets/img/alarm.png";
import moment from "moment";
import { useHistory } from "react-router-dom";
const NotificationSidebar = ({
  api,
  notification,
  getNotifications,
  setNotificationSideBar,
}: any) => {
  const history = useHistory();
  const [view, setView] = React.useState("");

  const readNotification = (id: any) => {
    api
      .post(`/api/notification/read_notifications`, { id })
      .then((res: any) => {
        if (res?.data?.data) {
          getNotifications();
        }
      });
  };

  const clear_notifications = () => {
    const ids = notification.map((i: any) => i.id);

    api
      .post(`/api/notification/clear_notifications`, { ids })
      .then((res: any) => {
        if (res?.data?.data) {
          getNotifications();
        }
      });
  };

  return (
    <div className={"active-notificationSideBar"}>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "10px",
        }}
      >
        {notification && notification.length > 0 && (
          <button
            className="btn btn-sm btn-clear"
            onClick={clear_notifications}
          >
            Clear All
          </button>
        )}
      </div>
      {notification && notification.length > 0 ? (
        notification.map((item: any, index: any) => {
          const condition = JSON.parse(item?.condition);

          return (
            <div
              key={index}
              className={
                item?.read_status == 0
                  ? `notification-card  `
                  : `notification-card-read`
              }
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (item?.read_status == 0) readNotification(item?.id);
              }}
            >
              <div
                style={{ fontFamily: "Open Sans, sans-serif" }}
                className="d-flex justify-content-between mb-3 "
                onClick={() => {
                  setNotificationSideBar(false);
                  history.push(item?.redirect, {
                    condition,
                  });
                }}
              >
                <div>{moment(item?.created_at).format("DD MMM. YYYY")}</div>
                <div> {moment.utc(item?.created_at).format("hh:mm A")}</div>
              </div>
              <div className="d-flex gap-2">
                <div>
                  <div
                    style={{
                      backgroundColor: "#f1f1f1",
                      borderRadius: "50%",
                    }}
                    className="p-2 "
                    onClick={() => {
                      setNotificationSideBar(false);
                      history.push(item?.redirect, {
                        condition,
                      });
                    }}
                  >
                    <img src={alarm} alt="alarm" width={20} />
                  </div>
                </div>
                <div className="flex-1 p-1">
                  <h6
                    className={`font-weight-bold ${
                      item?.read_status == 1 && "text-white"
                    }`}
                    onClick={() => {
                      setNotificationSideBar(false);
                      history.push(item?.redirect, {
                        condition,
                      });
                    }}
                  >
                    {item?.title}
                  </h6>
                  <div>
                    {view == item?.id ? (
                      <p>
                        {" "}
                        {item?.message}{" "}
                        {item?.message?.length > 50 && (
                          <i
                            onClick={() => setView("")}
                            className="fa-solid fa-chevron-up"
                          ></i>
                        )}
                      </p>
                    ) : (
                      <p>
                        {item?.message?.slice(0, 50)}
                        {item?.message?.length > 50 && "... "}
                        {item?.message?.length > 50 && (
                          <i
                            onClick={() => setView(item?.id)}
                            className="fa-solid fa-chevron-down"
                          ></i>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <img style={{ width: "100%" }} src={"/noNotify.png"} alt="notify" />
      )}
    </div>
  );
};

export default NotificationSidebar;
