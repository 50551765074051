export const registerServiceWorker = (): void => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration: ServiceWorkerRegistration) => {
        console.log("Service Worker Registered:", registration);
      })
      .catch((error: Error) => {
        console.error("Service Worker Registration Failed:", error);
      });
  }
};
