import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Seo from "../Seo/Seo";
import Application from "./Application";
import UpdateProfile from "./UpdateProfile";
import Appointment from "./Appointment";
import PaymentHistory from "./PaymentHistory";
import Shortlist from "./Shortlist";
import Counsellor from "./Counsellor";
import Discussion from "./Discussion";
import Review from "./Review";
import Service from "./Service";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { URL } from "../../redux/common/url";
import Breadcrumb from "../Breadcrumb";
import api from "../../redux/common/api";
function Profile() {
  const history = useHistory();
  const location = useLocation();
  const isAuthenticated: any = getToken();
  const [tab, setTab] = useState("updateProfile");
  const [title, settitle] = useState<any>("Student-Profile");
  const studentProfile: any = useSelector((state: any) =>
    state.student.getstudentById ? state.student.getstudentById : []
  );
  const [notification, setNotification] = useState([]);
  const [redirects, setRedirects] = useState([]);
  const logout = async () => {
    await localStorage.clear();
    toast.success("You have logged out successfully.");
    window.location.href = "/login";
  };
  const findGetParameter = (parameterName: any) => {
    var result = null,
      tmp = [];
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
    return result;
  };

  useEffect(() => {
    if (isAuthenticated == false) {
      history.push("/login", { logback: 1 });
    }
    var callbackAction = findGetParameter("action");
    if (callbackAction) {
      setTab(callbackAction);
    }
  }, []);

  useEffect(() => {
    const tabName = location.pathname.split("/").slice(-1)[0];
    console.log(location.pathname, "----");
    if (location.pathname.includes(tabName)) {
      setTab(tabName);
    }
    if (location.pathname.includes("/profile/basic_details")) {
      setTab("profile");
    }
    if (location.pathname.includes("/profile/education_details")) {
      setTab("profile");
    }
    if (location.pathname.includes("/profile/document")) {
      setTab("profile");
    }
    if (location.pathname.includes("/profile/interest")) {
      setTab("profile");
    }
  }, [location.pathname]);

  const getNotifications = () => {
    api.post(`/api/notification/getUser_active_notify`, {}).then((res: any) => {
      if (res?.data?.data) {
        setNotification(res?.data?.data);
        setRedirects(
          res?.data?.data.map((item: any) => {
            return item?.redirect;
          })
        );
      }
    });
  };

  const filterOutNavLink = (url: any) => {
    const filteredItem = notification.find((item: any) => {
      return item.redirect == url;
    });
    if (!filteredItem) {
      return {};
    } else {
      return JSON.parse(filteredItem?.condition);
    }
  };

  useEffect(() => {
    getNotifications();
    setTimeout(() => {
      getNotifications();
    }, 5000);
  }, []);

  return (
    <div>
      <Seo
        slug={window.location.pathname}
        modified_time={studentProfile?.updatedAt || studentProfile?.createdAt}
        image={
          studentProfile?.profile_image
            ? URL.API_BASE_URL + studentProfile?.profile_image
            : ""
        }
      />
      <section className="categories section profile_wrap bg-grey-light login_sec">
        <Breadcrumb page={"Profile"} data={{ slug: "", path: "" }} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 col-lg-3">
              <div className="shade1 user_nav bg-white">
                <ul>
                  <li>
                    <a
                      className={tab == "profile" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/basic_details");
                        setTab("profile");
                      }}
                    >
                      <img src="/assets/img/icons/profile.png" alt="" /> my
                      profile &nbsp;
                      {(redirects.includes("/profile/basic_details") ||
                        redirects.includes("/profile/document")) && (
                        <span className="fs-12 text-danger">&#9679;</span>
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className={tab == "applications" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/applications");
                        setTab("applications");
                      }}
                    >
                      <img src="/assets/img/icons/application.png" alt="" />{" "}
                      applications &nbsp;
                      {redirects.includes("/profile/applications") && (
                        <span className="fs-12 text-danger">&#9679;</span>
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className={tab == "appointments" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/appointments");
                        setTab("appointments");
                      }}
                    >
                      <img src="/assets/img/icons/appoinment.png" alt="" /> my
                      appointments &nbsp;
                      {redirects.includes("/profile/appointments") && (
                        <span className="fs-12 text-danger">&#9679;</span>
                      )}
                    </a>
                  </li>
                  {/* <li>
                    <a href="">
                      <img src="assets/img/icons/refer-earn.png" alt="" /> refer
                      &amp; earn
                    </a>
                  </li> */}
                  <li>
                    <a
                      className={tab == "payments" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/payments");
                        setTab("payments");
                      }}
                    >
                      <img src="/assets/img/icons/payment.png" alt="" /> payment
                      &nbsp;
                      {redirects.includes("/profile/payments") && (
                        <span className="fs-12 text-danger">&#9679;</span>
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className={tab == "shortlisted" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/shortlisted");
                        setTab("shortlisted");
                      }}
                    >
                      <img src="/assets/img/icons/shortlist.png" alt="" />{" "}
                      shortlisted &nbsp;
                      {redirects.includes("/profile/shortlisted") && (
                        <span className="fs-12 text-danger">&#9679;</span>
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className={tab == "counsellor" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/counsellor");
                        setTab("counsellor");
                      }}
                    >
                      <img src="/assets/img/icons/counsellor.png" alt="" />{" "}
                      counsellor/mentor &nbsp;
                      {redirects.includes("/profile/counsellor") && (
                        <span className="fs-12 text-danger">&#9679;</span>
                      )}
                    </a>
                  </li>
                  {/* <li>
                    <a href="javascript:void(0)">
                      <img src="assets/img/icons/video.png" alt="" /> recorded
                      videos
                    </a>
                  </li> */}
                  <li>
                    <a
                      className={tab == "discussions" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/discussions");
                        setTab("discussions");
                      }}
                    >
                      <img src="/assets/img/icons/discussion.png" alt="" />{" "}
                      discussions &nbsp;
                      {/* {redirects.includes("/profile/discussions") && (
                        <span className="fs-12 text-danger">&#9679;</span>
                      )} */}
                    </a>
                  </li>
                  <li>
                    <a
                      className={tab == "reviews" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/reviews");
                        setTab("reviews");
                      }}
                    >
                      <img src="/assets/img/icons/review_profile.png" alt="" />{" "}
                      reviews &nbsp;
                      {redirects.includes("/profile/reviews") && (
                        <span className="fs-12 text-danger">&#9679;</span>
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className={tab == "services" ? "active" : ""}
                      onClick={() => {
                        history.push("/profile/services");
                        setTab("services");
                      }}
                    >
                      <img src="/assets/img/icons/services.png" alt="" />{" "}
                      services &nbsp;
                      {redirects.includes("/profile/services") && (
                        <span className="fs-12 text-danger">&#9679;</span>
                      )}
                    </a>
                  </li>
                  <li>
                    <a onClick={logout}>
                      <img src="/assets/img/icons/logout.png" alt="" /> logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {["profile", "profile/basic_details"].includes(tab) ? (
              <UpdateProfile
                fileds={{
                  ...filterOutNavLink("/profile/basic_details"),
                  ...filterOutNavLink("/profile/document"),
                }}
              />
            ) : (
              ""
            )}
            {tab == "applications" ? (
              <Application fileds={filterOutNavLink("/profile/applications")} />
            ) : (
              ""
            )}
            {tab == "appointments" ? (
              <Appointment fileds={filterOutNavLink("/profile/appointments")} />
            ) : (
              ""
            )}
            {tab == "payments" ? (
              <PaymentHistory fileds={filterOutNavLink("/profile/payments")} />
            ) : (
              ""
            )}
            {tab == "shortlisted" ? (
              <Shortlist fileds={filterOutNavLink("/profile/shortlisted")} />
            ) : (
              ""
            )}
            {tab == "counsellor" ? (
              <Counsellor fileds={filterOutNavLink("/profile/counsellor")} />
            ) : (
              ""
            )}
            {tab == "discussions" ? <Discussion /> : ""}
            {tab == "reviews" ? (
              <Review fileds={filterOutNavLink("/profile/reviews")} />
            ) : (
              ""
            )}
            {tab == "services" ? (
              <Service fileds={filterOutNavLink("/profile/services")} />
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Profile;
