import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import {
  LOADING,
  GET_ALL_COUNSELLOR,
  GET_COUNSELLOR_BY_ID,
  GET_COACH_AND_COUNSELLOR,
  GET_COUNSELLOR_INFO,
  GET_COUNSELLOR_BY_SLUG,
} from "../common/constant";

const loading = (data: any) => {
  return { type: LOADING, payload: data };
};

export const setLoading = (data: any) => {
  return async (dispatch: any) => {
    dispatch(loading(data));
  }}


const getAllCounsellorAction = (data: any) => {
  return { type: GET_ALL_COUNSELLOR, payload: data };
};

const getCounsellorbyIdAction = (data: any) => {
  return { type: GET_COUNSELLOR_BY_ID, payload: data };
};
const getcoachcounsellorAction = (data: any) => {
  return { type: GET_COACH_AND_COUNSELLOR, payload: data };
};
const getCounsellorInfoAction = (data: any) => {
  return { type: GET_COUNSELLOR_INFO, payload: data };
};
const getCounsellorBySlugAction = (data: any) => {
  return { type: GET_COUNSELLOR_BY_SLUG, payload: data };
};

export const getAllCounsellor = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      // const response: any = await api.get(`${URL.getAllCounsellor}`);
      const response: any = await api.post(`${URL.getAllCounsellor}`, {
        experience: "",
        rate_per_hr: "",
        preferred_country: [],
        sort_by: "",
        offset: 0,
        limit: 500,
      });
      // console.log(response.data.data, "test test");

      dispatch(getAllCounsellorAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getCounsellorInfo = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.post(`${URL.getCounsellorInfo}`, data);
      dispatch(getCounsellorInfoAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getcoachcounsellor = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getcoachcounsellor}`);
      dispatch(getcoachcounsellorAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getCounsellorbyId = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getCounsellorbyId}${data}`);
      dispatch(getCounsellorbyIdAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getCounsellorBySlug = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(
        `${URL.getCounsellorBySlug}?slug=${data}`
      );
      dispatch(getCounsellorBySlugAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
