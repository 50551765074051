import React, { useState, useEffect } from "react";
import Contactus from "./Contactus";
import ContactForm from "./ContactForm";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { officeAction } from "../../redux/common/action";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import SocialShareButton from "../SocialShareButton";
import Seo from "../Seo/Seo";
import Breadcrumb from "../Breadcrumb";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";
import { onlyNameFilter, onlyNumberFilter } from "../../validation/Validation";
import ReCAPTCHAComponent from "./ReCAPTCHA";
var other_details: any = [];
function Accommodation() {
  const urlsforShare = window.location.href;
  const history = useHistory();
  const dispatch = useDispatch();
  const [Accommodation, setAccommodation] = useState<any>();
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>();
  // setAccommodation([]);
  const [bedRoom, setBedRoom] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [bathRoom, setBathRoom] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [moveIn, setMoveIn] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [moveOut, setMoveOut] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [college, setCollege] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [property, setProperty] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [country, setCountry] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [page, setPage] = useState<any>();

  const countrys: any[] = useSelector((state: any) =>
    state.country.countryList ? state.country.countryList : []
  );
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const handleRecaptchaChange = (value: any) => {
    setRecaptchaValue(value);
  };

  const hendlereviews = (e: any) => {
    const { name, value } = e.target;
    let isValidName = /^[A-Za-z\s]*$/.test(value);
    let isValid = /^[0-9]{0,10}$/.test(value);
    if (name === "name") {
      if (!isValidName) {
        alert("Only alphabets and spaces are allowed for the name.");
      }
      setAccommodation({
        ...Accommodation,
        [name]: value,
      });
    } else if (name === "mobile") {
      // Allow only numeric input for the mobile field

      if (!isValid) {
        alert("Please enter only numeric values up to 10 digits.");
      }
      if (isValid) {
        setAccommodation({
          ...Accommodation,
          [name]: value,
          other_details: other_details,
          service_name: "accommodation",
        });
      }
    } else {
      setAccommodation({
        ...Accommodation,
        [name]: value,
      });
    }
  };
  const [minDate, setMinDate] = useState<any>();

  const submitQuery = (e: any) => {
    const Name = $("#Name").val();
    const Mobile: any = $("#Mobile").val();
    const Email: any = $("#Email").val();
    const City = $("#City").val();
    const IsBedroom = $("input[name=bedroom]").val();
    const IsBathroom = $("input[name=bathroom]").val();
    // const MoveIn = moment($("input[name=move_in]").val()).format('YYYY-MM-DD');
    // const MoveOut = moment($("input[name=move_out]").val()).format('YYYY-MM-DD');
    const MoveIn = moment(startDate).format("YYYY-MM-DD");
    const MoveOut = moment(endDate).format("YYYY-MM-DD");
    const IsUniversity = $("input[name=college]").val();
    const PropertyType = $("select[name=property]").val();
    const IsCountry = $("select[name=country]").val();
    // console.log(MoveIn);
    // console.log(MoveOut);
    // return false;
    // if (!recaptchaValue) {
    //   alert("Please complete the reCAPTCHA!");
    //   return;
    // }

    $(".error-class").remove();
    var isError = false;
    var phoneno = /^[1-9]{1}[0-9]{9}$/;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!Name) {
      $("#Name").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (!Mobile) {
      $("#Mobile").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (Mobile) {
      if (Mobile.length != 10) {
        $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
        isError = true;
      }
    }
    if (Mobile) {
      if (phoneno.test(Mobile) === false) {
        $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
        isError = true;
      }
    }
    if (!Email) {
      $("#Email").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (Email) {
      if (regex.test(Email) === false) {
        $("#Email").after('<div class="error-class">Invalid email.</div>');
        isError = true;
      }
    }
    if (!City) {
      $("#City").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (isError) {
      return false;
    }

    var Country = {
      name: "country",
      label: "country",
      value: IsCountry ? IsCountry : "N/A",
    };
    var UniversityName = {
      name: "college",
      label: "college/university",
      value: IsUniversity ? IsUniversity : "N/A",
    };
    var Property = {
      name: "property",
      label: "property",
      value: PropertyType ? PropertyType : "N/A",
    };
    var IsMoveOut = {
      name: "move_out",
      label: "move out",
      value: MoveOut ? MoveOut : "N/A",
    };
    var IsMoveIn = {
      name: "move_in",
      label: "move in",
      value: MoveIn ? MoveIn : "N/A",
    };
    var BathRoom = {
      name: "bathroom",
      label: "bathroom",
      value: IsBathroom ? IsBathroom : "N/A",
    };
    var BedRoom = {
      name: "bedroom",
      label: "bedroom",
      value: IsBedroom ? IsBedroom : "N/A",
    };

    other_details.push(
      Country,
      Property,
      IsMoveIn,
      IsMoveOut,
      UniversityName,
      BathRoom,
      BedRoom
    );
    $("#AccommodationIn").prop("disabled", true);

    dispatch(officeAction.submitQuery(Accommodation, history));
  };
  const setMinimumDate = (val: any) => {
    if (val) {
      setMoveOut({
        value: "",
      });
      setMinDate(val);
    }
  };

  const seo: any = useSelector((state: any) => state.setting.seo);
  // useEffect(() => {
  //   window?.scrollTo(0, 0);
  //   })

  return (
    <>
      <Seo slug={window.location.pathname} />
      <div>
        <section className="tag-term">
          <Breadcrumb
            page={"Services"}
            data={{ slug: "Accommodation", path: "/services" }}
          />
          <div className="text-right pb-4 mr-3">
            <SocialShareButton urlsforShare={urlsforShare} data={""} />
          </div>
          <div className="headingmains text-center">
            <h2
              className="titlewithline aos-init aos-animate"
              data-aos="flip-left"
            >
              accommodation
              <span>
                <img
                  className="service-funding"
                  src="assets/img/ser03.png"
                  alt=""
                />
              </span>
            </h2>
          </div>
        </section>
        <section className="terms">
          <div className="container">
            <div className="row">
              <div className="terms-s d-flex">
                <div className="col-lg-12">
                  <p>
                    Are you planning to study in another country and need to
                    find suitable accommodation? Accommodation is almost
                    certainly one of the first things that come to mind when
                    thinking about reading abroad, and your final decision may
                    be dependent entirely on a variety of criteria. There are
                    numerous options for international scholar housing, as well
                    as various concerns and checkpoints you must go through
                    before making your choice - which is almost certain to be
                    partially based entirely on personal desire. Whatever your
                    budget, whether you're looking for college housing or
                    anything in the private sector, there may be an option for
                    you. Simply look through a few of the greatest
                    accommodations on our website to make the procedure as
                    simple and painless as possible.
                  </p>
                </div>
                {/* <div className="col-lg-5">
                                    <ContactForm />
                                </div> */}
              </div>
            </div>
            {seo?.header_content && (
              <div className="about-uni-text shade1 mt-4">
                <SeoHeaderContent />
              </div>
            )}
            <div className="terms-s">
              <div className="postwrap">
                <div className="container py-2">
                  <article className="postcard">
                    <img
                      className="postcard__img-new"
                      src="assets/img/acc1.png"
                      alt=""
                    />
                    <div className="postcard__text postcard__text1">
                      <h4 className="pb-3 card-head1">
                        What is the definition of accommodation?
                      </h4>
                      <span className="border-bt"></span>
                      <div className="postcard__preview-txt ft-15">
                        If you've decided to study in a foreign nation, you'll
                        have to make a number of important decisions. One of the
                        most important decisions you'll make is where to reside
                        for the duration of your program. Where you choose to
                        live will have an impact on your entire overseas
                        experience. It will have an impact on your social life
                        as well as your academic performance. Finding a place to
                        live that meets all of your requirements might be
                        difficult. As a global student, this may be your first
                        time living on your own or even traveling outside of
                        your home country. It'll also be the first time you've
                        had to deal with fees and any type of accommodation. The
                        concept behind University Rooms is that vacant dorm
                        rooms are rented out, with availability typically during
                        the summer months, but also during the academic year.
                        This allows people (who are no longer just students) to
                        live in otherwise-empty university dorm rooms in shared,
                        single, or studio residences. This appears to be a
                        viable option for university sports or visits,
                        particularly with large groups, for shorter trips
                        abroad. As a result, it's vital to recognize that this
                        is a primary option right away. Your student
                        accommodation could be a place where you unwind,
                        explore, and, most importantly, meet new people. As a
                        result, to make the process easier, we provide student
                        accommodation that will assist you in finding a suitable
                        residence.
                      </div>
                    </div>
                  </article>
                </div>
              </div>

              <div className="terms-s">
                <div className="postwrap">
                  <div className="container py-2">
                    <article className="postcard postcard-n1">
                      <img
                        className="postcard__img-new"
                        src="assets/img/acc2.png"
                        alt=""
                      />
                      <div className="postcard__text postcard__text1">
                        <h4 className="pb-3 card-head1">How does it work?</h4>
                        <span className="border-bt"></span>
                        <div className="postcard__preview-txt ft-15">
                          Are you looking for an accommodation that has been
                          provided? We've got you covered! We provide the
                          largest selection of residences to help you find your
                          next home. Simply type in the town where you want to
                          stay and hit the seek button! You may be offered
                          apartments with condominium rates, services, and
                          specific neighborhoods. We supply particular types of
                          provided lodgings such as rooms, residences, flats,
                          houses, scholar residencies, and studios from Europe
                          to America, Australia to Asia. Moving to a new area is
                          usually a difficult process, and the first and most
                          important step is to find the right apartment. Because
                          we're aware of the issue, we need to make this process
                          easier for everyone seeking a place to stay in a new
                          location.
                        </div>
                      </div>
                    </article>
                  </div>
                </div>

                <div className="postwrap">
                  <div className="container py-2">
                    <article className="postcard">
                      <img
                        className="postcard__img-new"
                        src="assets/img/acc3.png"
                        alt=""
                      />
                      <div className="postcard__text postcard__text1">
                        <h4 className="pb-3 card-head1">Why pick out us?</h4>
                        <span className="border-bt"></span>
                        <div className="postcard__preview-txt ft-15">
                          If you want to start your foreign adventure with new
                          friends, Admissify is a fantastic way to do so. While
                          it's best for those who don't mind sharing a flat (or
                          room) with others, the number of housing criteria and
                          options for finding the ideal vegan yoga master is
                          rather astounding (this listing is going all of the
                          manners right all the way down to puppy kind filters,
                          in-unit washers, and wifi connection types). The
                          ability to connect with other consumers right away is
                          top-notch fast, easy, and efficient, so you don't have
                          to wait for long and awkward "you've been matched!"
                          results prior to concluding who you need to be
                          companions with. International student accommodation
                          options are just as simple and entertaining to look
                          through. Even better: they may be tailored to you, the
                          harried and ecstatic student, in order to focus on
                          removing all of your worries and problems from the
                          house-hunting process. Now that staying in a foreign
                          country is just another fun step in the process, the
                          desire to travel abroad can be reignited in full
                          force. Whether you're relocating to distant Kansas or
                          far Amsterdam, be prepared to say "home," "sweet," and
                          "home" in whichever new language you're about to
                          learn!
                        </div>
                      </div>
                    </article>
                    {seo?.footer_content && (
                      <div className="about-uni-text shade1 mt-5">
                        <SeoFooterContent />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <br />

                <Contactus /> */}

        <section className="categories section bg-grey" id="c-form">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-12">
                <div
                  style={{
                    visibility: "hidden",
                    position: "absolute",
                    width: "0px",
                    height: "0px",
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <symbol viewBox="0 0 24 24" id="expand-more">
                      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </symbol>
                    <symbol viewBox="0 0 24 24" id="close">
                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </symbol>
                  </svg>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="apply-bx" style={{ backgroundColor: "#fff" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-11 col-lg-10">
                        <h5 className="apply-n">apply now</h5>
                        <p className="b-10 coach-view">
                          Flexible living. Monthly stays. Anywhere. Move-in
                          ready apartments across 9 Countries & 500+ cities
                        </p>
                      </div>
                      <div className="col-md-12 col-lg-12">
                        <div className="fields-wrp">
                          <form id="Accommodation" autoComplete="new-password">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    type="text"
                                    autoComplete="new-off"
                                    name="name"
                                    value={Accommodation?.Name}
                                    id="Name"
                                    onChange={(e) => {
                                      hendlereviews(e);
                                    }}
                                    onInput={onlyNameFilter}
                                  />
                                  <label>name</label>
                                  <span className="fa fa-user p-2"></span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    name="mobile"
                                    maxLength={10}
                                    type="tel"
                                    inputMode="numeric"
                                    id="Mobile"
                                    value={Accommodation?.Mobile}
                                    onChange={(e) => {
                                      hendlereviews(e);
                                    }}
                                    onInput={onlyNumberFilter}
                                  />
                                  <label>mobile</label>
                                  <span className="fa fa-phone p-2"></span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="email"
                                    id="Email"
                                    value={Accommodation?.Email}
                                    onChange={(e) => {
                                      hendlereviews(e);
                                    }}
                                  />
                                  <label>email</label>
                                  <span className="fa fa-envelope p-2"></span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="city"
                                    id="City"
                                    // maxLength="10"
                                    value={Accommodation?.City}
                                    onChange={(e) => {
                                      hendlereviews(e);
                                    }}
                                  />
                                  <label>city</label>
                                  <span className="fa fa-map-marker p-2"></span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="bedroom"
                                    value={bedRoom?.bedroom}
                                  />
                                  <label>bedroom</label>
                                  <span className="fa fa-bed p-2"></span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="bathroom"
                                    value={bathRoom?.bathroom}
                                  />
                                  <label>bathroom</label>
                                  <span className="fa fa-bath p-2"></span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="field">
                                  {/* <input
                                                                        autoComplete="new-off"
                                                                        type="date"
                                                                        name="move_in"
                                                                        value={moveIn?.move_in}
                                                                        onChange={(e) => setMinimumDate(e.target.value)} /> */}
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(date: Date) => {
                                      setStartDate(date);
                                      setEndDate("");
                                    }}
                                    peekNextMonth
                                    placeholderText="move in"
                                    showMonthDropdown
                                    showYearDropdown
                                    name="move_in"
                                    dateFormat="dd-MM-yyyy"
                                    dropdownMode="select"
                                    autoComplete="new-off"
                                    id="move_in"
                                    // maxDate={moveIn?.move_in}
                                  />
                                  {/* <label>move in</label> */}
                                  <span className="fa fa-male p-2"></span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  {/* <input
                                                                        autoComplete="new-off"
                                                                        type="date"
                                                                        name="move_out"
                                                                        min={minDate ? minDate : null}
                                                                        value={moveOut?.move_out} /> */}
                                  <DatePicker
                                    selected={endDate}
                                    onChange={(date: Date) => setEndDate(date)}
                                    peekNextMonth
                                    placeholderText="move out"
                                    showMonthDropdown
                                    showYearDropdown
                                    autoComplete="new-off"
                                    name="move_out"
                                    dateFormat="dd-MM-yyyy"
                                    dropdownMode="select"
                                    id="move_out"
                                    minDate={startDate ? startDate : null}
                                  />
                                  {/* <label>move out</label> */}
                                  <span className="fa fa-male p-2"></span>
                                </div>
                              </div>
                            </div>
                            <div className="field">
                              <input
                                autoComplete="new-off"
                                type="text"
                                name="college"
                                value={college?.college}
                              />
                              <label>college/university</label>
                              <span className="fa fa-university p-2"></span>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="field">
                                  <span className="fa fa-home p-2"></span>
                                  <select
                                    className="form-control c-select"
                                    name="property"
                                    value={property?.property}
                                  >
                                    <option value="">property type</option>
                                    <option value="Studio">Studio</option>
                                    <option value="Shared">Shared</option>
                                    <option value="Private">Private</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  <span className="fa fa-map-marker p-2"></span>
                                  <select
                                    className="form-control c-select"
                                    name="country"
                                    value={country?.country}
                                  >
                                    <option value="">preferred country</option>
                                    {countrys &&
                                      countrys?.map((data: any, i: any) => {
                                        return (
                                          <option
                                            key={i}
                                            value={data?.country_name}
                                          >
                                            {data?.country_name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="field">
                              <textarea
                                autoComplete="new-off"
                                className="q-msg border__"
                                // rows="4"
                                // cols="50"
                                placeholder="message"
                                value={Accommodation?.message}
                                onChange={(e) => {
                                  hendlereviews(e);
                                }}
                                name="message"
                              ></textarea>
                            </div>
                            <br />
                            <br />
                            <br />
                            {/* <ReCAPTCHAComponent
                              onRecaptchaChange={handleRecaptchaChange}
                            /> */}
                            <div className="row">
                              <div className="col-md-12">
                                <div className="fieldbtn text-center">
                                  <div
                                    onClick={(e) => {
                                      submitQuery("Accommodation");
                                    }}
                                    // type="button"
                                    data-toggle="modal"
                                    className="bggreadent apply-sub-btn a-s-b"
                                    id="AccommodationIn"
                                  >
                                    submit
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Accommodation;
