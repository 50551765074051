import React from "react";

function AppOn() {
  return (
    <>
      <div>
        <section
          className="categories section appbg aos-init aos-animate"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div className="container">
            <div className="col-md-8 sub-section ">
              <div className="headingmains text-left">
                <h2>
                  Download the{" "}
                  <img className="scale" src="/assets/img/logonews1-app.png" />{" "}
                  app on
                </h2>
                <div className="appdown">
                  <h3>Apply from over 3000+ Universities</h3>
                  <ul>
                    <li>
                      <a
                        href={
                          "https://apps.apple.com/in/app/admissify/id1235485892"
                        }
                        target="_blank"
                      >
                        <img className="scale" src="/assets/img/app1.png" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          "https://play.google.com/store/apps/details?id=com.admissify"
                        }
                        target="_blank"
                      >
                        <img className="scale" src="/assets/img/app2.png" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <ul className="row cate-box w-100">
                <li className="job-categorynew">
                  <div>
                    <span className="icon-circle">
                      <img
                        className="scale"
                        src="/assets/img/app-icon1.png"
                        alt=""
                      ></img>
                    </span>
                    <h6 className="title">Smart Search</h6>
                    <p>Auto shortlist from 3000+ top universities</p>
                  </div>
                </li>

                <li className="job-categorynew">
                  <div>
                    <span className="icon-circle">
                      <img
                        className="scale"
                        src="/assets/img/app-icon2.png"
                        alt=""
                      ></img>
                    </span>
                    <h6>Chat</h6>
                    <p>Chat with best alumni counsellors</p>
                  </div>
                </li>
                <li className="job-categorynew">
                  <div>
                    <span className="icon-circle">
                      <img
                        className="scale"
                        src="/assets/img/app-icon3.png"
                        alt=""
                      ></img>
                    </span>
                    <h6 className="title">Track </h6>
                    <p>Track status of live applications</p>
                  </div>
                </li>
                <li className="job-categorynew">
                  <div>
                    <span className="icon-circle">
                      <img
                        className="scale"
                        src="/assets/img/app-icon4.png"
                        alt=""
                      ></img>
                    </span>
                    <h6 className="title">Apply</h6>
                    <p>Use the admissify’s common app e-form</p>
                  </div>
                </li>
                <li className="job-categorynew">
                  <div>
                    <span className="icon-circle">
                      <img
                        className="scale"
                        src="/assets/img/app-icon5.png"
                        alt=""
                      ></img>
                    </span>
                    <h6 className="title">News & FAQs</h6>
                    <p>Scholarship, student and immigration news</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AppOn;
