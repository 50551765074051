import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "./header";
import Footer from "./footer";
import AppRoute from "../route";
import ScrollToTop from "../Component/ScrollToTop/ScrollToTop";
import LoadingScreen from "../Component/LoadingScreen/LoadingScreen";
import { addChatWidget } from "../Utils/Common/Common";
import DownloadAppPopup from "../Component/downloadAppPopup";
import NotificationSidebar from "../Component/Notification/NotificationSidebar";
import Modal from "react-responsive-modal";
import Popup from "../Component/downloadAppPopup/popup";
import { messaging } from "../firebase";
import { onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import api from "../redux/common/api";

function Layout() {
  const [downloadAppPopup, setDownloadAppPopup] = useState(false);
  const [notificationSideBar, setNotificationSideBar] = useState(false);
  const [notification, setNotification] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      const appSuggestionDisplayed: string | null =
        sessionStorage.getItem("itemClicked");
      const windowWidth = window.innerWidth;
      console.log(windowWidth);
      if (!appSuggestionDisplayed && windowWidth < 600) {
        setDownloadAppPopup(true);
        // sessionStorage.setItem("appSuggestion", "true");
      } else {
        setDownloadAppPopup(false);
      }
    };
    // Initial check on mount
    handleResize();
    // Add event listener to resize event
    window.addEventListener("resize", handleResize);
    // Clean up event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  addChatWidget();
  const commenreducer: any = useSelector(
    (state: any) => state.commenreducer.loading && state.commenreducer.loading
  );

  const getNotifications = () => {
    api.post(`/api/notification/getUser_notifications`, {}).then((res: any) => {
      if (res?.data?.data) {
        setNotification(res.data.data);
      }
    });
  };

  // Foreground Notifications
  onMessage(messaging, (payload: any) => {
    var audio = new Audio("/notify.wav");
    audio.play();
    getNotifications();
    console.log("Foreground Notification Received:", payload);
    toast.info(
      `🔔 ${payload.notification.title}\n${payload.notification.body}`
    );
  });

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <Header
        notificationLength={
          notification.filter((item: any) => item?.read_status == 0).length
        }
        setNotificationSideBar={setNotificationSideBar}
      ></Header>
      <ScrollToTop />
      <AppRoute></AppRoute>
      {commenreducer && <LoadingScreen />}
      {downloadAppPopup && <Popup myFunction={setDownloadAppPopup} />}
      {notificationSideBar && (
        <div
          onClick={() => setNotificationSideBar((p: any) => !p)}
          className="notification-container"
        >
          {" "}
        </div>
      )}
      {notificationSideBar && (
        <NotificationSidebar
          api={api}
          getNotifications={getNotifications}
          setNotificationSideBar={setNotificationSideBar}
          notification={notification}
        />
      )}
      <Footer></Footer>
    </>
  );
}

export default Layout;
