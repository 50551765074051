import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import {
  streamAction,
  studentAction,
  UniversityAction,
  UniversityCourseAction,
  SettingAction,
} from "../../redux/common/action";
import MenuOutSideClick from "./outsideClick";
import { URL } from "../../redux/common/url";
import { toast } from "react-toastify";
import { Dropdown, Menu, Button, AutoComplete, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import WelComeForm from "./WelComeForm";
import api from "../../redux/common/api";

function Header({ notificationLength, setNotificationSideBar }: any) {
  var Slug = window.location.pathname;
  var SplitUrl = Slug.substring(0, Slug.lastIndexOf("-") + 1);
  const location = useLocation();
  const history = useHistory();
  const id: any = useLocation();
  const baseurl = URL.API_BASE_URL;
  const anchorRef1 = useRef(null);
  const anchorRef2 = useRef(null);
  const [anchor1, setAnchor1] = useState(false);
  const [anchor2, setAnchor2] = useState(false);
  MenuOutSideClick(anchorRef1, setAnchor1);
  MenuOutSideClick(anchorRef2, setAnchor2);
  const { pathname } = location;
  const dispatch = useDispatch();
  const splitLocation = pathname.split("/");
  const [courseFil, setCourseFil] = useState("");
  const isAuthenticated: any = getToken();
  const [streamOpne, setStreamOpne] = useState<any>("");
  const [courseOpne, setcourseOpne] = useState<any>("");
  const [toggelOpne, settoggelOpne] = useState<any>("");
  const [uniandcou, setuniandcou] = useState<any>();
  const [searchRe, setsearchRe] = useState<any>("");
  const [countries, setCountries] = useState<any>([]);
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);

  type BlogOptionsType = {
    [key: string]: string; // or you can use a more specific type if you know the exact keys
  };
  const blogOptions: BlogOptionsType = {
    ["Study Abroad"]: "https://www.admissify.com/blog/category/study-abroad/",
    ["Study In India"]:
      "https://www.admissify.com/blog/category/study-in-india/",
    ["Test Preparation"]: "https://www.admissify.com/blog/category/test-prep/",
    ["Upskilling"]: "https://www.admissify.com/blog/category/upskilling/",
  };

  const logout = async () => {
    let deviceToken = localStorage.getItem("device_token");

    api
      .post(`/api/student/logout`, { device: deviceToken })
      .then((res) => {
        if (res?.data) {
          localStorage.clear();
          /* history.push("/login") */
          window.location.href = "/login";
          toast.success("You have logged out successfully.");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const getCountries = async () => {
    api
      .get(`/api/country/list`)
      .then((res) => {
        if (res?.data?.data) {
          console.log("countries", res?.data?.data);

          setCountries(res?.data?.data);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    getCountries();
  }, []);

  const hendleOpneOff = (e: any) => {
    if (streamOpne == e) {
      setStreamOpne("");
    } else {
      setStreamOpne(e);
    }
  };

  const hendlecouOpneOff = (e: any) => {
    if (courseOpne == e) {
      setcourseOpne("");
    } else {
      setcourseOpne(e);
    }
  };

  const togglehendle = (e: any) => {
    if (toggelOpne == true) {
      settoggelOpne(false);
    } else {
      settoggelOpne(e);
      setStreamOpne("");
      setcourseOpne("");
    }
  };

  const itemMap = new Map();

  const getSetting: any = useSelector((state: any) =>
    state.setting.getSetting ? state.setting.getSetting : []
  );
  const studentProfile: any = useSelector((state: any) =>
    state.student.getstudentById ? state.student.getstudentById : []
  );

  const universities: any = useSelector((state: any) =>
    state.unversity.universities?.data ? state.unversity.universities?.data : []
  );

  const getallUniversity: any = useSelector((state: any) =>
    state.unversity.universities ? state.unversity.universities : []
  );

  const allCourse: any = useSelector((state: any) =>
    state.universitycourse.allCourse ? state.universitycourse.allCourse : []
  );

  const coursess: any = useSelector((state: any) =>
    state.universitycourse.universitycourse
      ? state.universitycourse.universitycourse
      : []
  );

  const allstreams: any = useSelector((state: any) =>
    state.stream.streamList ? state.stream.streamList : []
  );

  const activeStream =
    allstreams &&
    allstreams?.filter((data: any) => {
      return data?.status == 1;
    });

  const keys = ["stream_name"];
  const filtered =
    activeStream &&
    activeStream?.filter(
      (
        (s) => (o: any) =>
          ((k) => !s.has(k) && s.add(k))(keys.map((k: any) => o[k]).join("|"))
      )(new Set())
    );

  // console.log(filtered);

  const acitveCourse =
    allCourse &&
    allCourse?.filter((data: any) => {
      return data?.status == 1;
    });

  const closeSideBar = () => {
    $("#toggleBar").removeClass("opened");
    $("#toggleOverlay").css("display", "none");
    $("#closeToggleBar").css("display", "none");
  };

  const courseStream =
    acitveCourse &&
    acitveCourse?.filter((data: any) => {
      return data?.Stream?.stream_name == courseFil;
    });

  const courseUg_ =
    courseStream &&
    courseStream?.filter((data: any) => {
      return data?.Stream?.course_level_id == 1;
    });

  const courseUg = courseUg_.sort((a: any, b: any) =>
    a.title > b.title ? 1 : b.title > a.title ? -1 : 0
  );

  const coursePg_ =
    courseStream &&
    courseStream?.filter((data: any) => {
      return data?.Stream?.course_level_id == 2;
    });
  const coursePg = coursePg_.sort((a: any, b: any) =>
    a.title > b.title ? 1 : b.title > a.title ? -1 : 0
  );

  const courseStreamOpne =
    allCourse &&
    allCourse?.filter((data: any) => {
      return data?.Stream?.stream_name == streamOpne;
    });

  const courseUgOpne =
    courseStreamOpne &&
    courseStreamOpne?.filter((data: any) => {
      return data?.Stream?.course_level_id == 1;
    });

  const coursePgOpne =
    courseStreamOpne &&
    courseStreamOpne?.filter((data: any) => {
      return data?.Stream?.course_level_id == 2;
    });

  // useEffect(() => {
  //   dispatch(
  //     UniversityAction.getuniversities({
  //       search_by: "",
  //       offset: 0,
  //       country_id: [],
  //       // limit: 10,
  //     })
  //   );
  //   return () => {};
  // }, []);

  const uniAndCouresHendle = (e: any) => {
    setsearchRe(e);
    dispatch(
      UniversityAction.getuniversities({
        search_by: e,
        searching: true,
        offset: 0,
        country_id: [],
        /*  limit:10 */
      })
    );
    return () => {};
  };

  const hendlestream = (value: any) => {
    setCourseFil(value);
  };

  useEffect(() => {
    if (user?.id) {
      dispatch(studentAction.getStudentById(user?.id, ""));
    }
    return () => {};
  }, [user?.id]);

  useEffect(() => {
    dispatch(SettingAction.getSetting({}));
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(UniversityCourseAction.getAllCourses({}));
    return () => {};
  }, []);

  // useEffect(() => {
  //   dispatch(UniversityCourseAction.getAllUniCourses({}));
  //   return () => {};
  // }, []);
  const options = universities
    ? universities.map((item: any) => {
        itemMap.set(item.university_name, item); // Store item by its name
        return {
          value: item.university_name,
          key: item.id,
        };
      })
    : [];

  const hendlback = (id: any) => {
    history.push("/universities", { course_id: id });
  };

  useEffect(() => {
    setCourseFil(allstreams[0]?.stream_name);
  }, [allstreams]);

  useEffect(() => {
    dispatch(streamAction.getstreamlist({}));

    return () => {};
  }, []);

  // const menu = (
  //   <Menu>
  //     {universities &&
  //       universities.slice(0, 15).map((data, i) => (
  //         <Menu.Item
  //           key={data.id} // Use a unique key for each item
  //           onClick={() => handleMenuClick(data)}
  //         >
  //           {data.university_name}
  //         </Menu.Item>
  //       ))}
  //   </Menu>
  // );

  // const menu = universities.slice(0, 15).map((data: any, i: any) => {
  //   return {
  //     label: data.university_name,
  //     key: data.id,
  //   };
  // });

  const handleSelect = (value: any) => {
    const selectedItem = itemMap.get(value); // Retrieve the full item data using value
    if (selectedItem) {
      handleMenuClick(selectedItem); // Pass the full item to handleMenuClick
    }
  };

  // Handle click on menu item
  const handleMenuClick = (data: any) => {
    history.push(`/university/${data.slug}`, { university_id: data.id });
    setsearchRe("");
    dispatch(UniversityAction.getUniversityBySlug(data.slug));
  };
  // useEffect(() => {
  //   dispatch(UniversityAction.getuniversities({searchinput:"count"}));
  //   return () => {};
  // }, []);

  const closeMenu = () => {
    if (window.innerWidth < 900) {
      $(".tg-fullmegamenu").hide();
    }
  };
  const showMenu = () => {
    if (window.innerWidth < 900) {
      $(".tg-fullmegamenu").show();
    }
  };
  setInterval(function () {
    if (window.innerWidth > 820) {
      $(".tg-fullmegamenu").show();
    }
  }, 100);

  return (
    <>
      <div
        className="topbar-section"
        style={{ display: SplitUrl === "/top-universities-in-" ? "none" : "" }}
      >
        <div className="container">
          <div className="topbar-inner">
            <div className="top-bar-left pull-left">
              <ul>
                <li>
                  <a href={"tel:" + getSetting[0]?.phone}>
                    <img src="/assets/img/phone-icon0111.png" alt="" />{" "}
                    {getSetting[0]?.phone}
                  </a>{" "}
                  |{" "}
                  <a href={"tel:" + getSetting[0]?.mobile}>
                    {getSetting[0]?.mobile}
                  </a>
                </li>
                <li>
                  <a
                    href={
                      "https://api.whatsapp.com/send?phone=" +
                      getSetting[0]?.whatsapp_no +
                      "&text= Hi, I am looking for support with my career and education planning."
                    }
                    target="_blank"
                  >
                    <img src="/assets/img/whatsapp-icon01.png" alt="" />{" "}
                    whatsapp us
                  </a>
                </li>
              </ul>
            </div>

            <div className="top-bar-right pull-right righttop">
              <ul
                className="mobile_flex"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li className="p-0">
                  <AutoComplete
                    options={options}
                    onSelect={handleSelect}
                    onSearch={(search: any) => {
                      uniAndCouresHendle(search);
                    }}
                    style={{
                      width: "300px",
                      alignSelf: "center",
                      marginTop: "-3%",
                    }}
                  >
                    <Input
                      className="university-search-input"
                      style={{
                        padding: "7px",
                        backgroundColor: "#CF9512",
                        border: "none",
                        paddingLeft: "50px",
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px",
                        color: "white",
                      }}
                      placeholder="Search Universities"
                      prefix={
                        <SearchOutlined
                          style={{ fontSize: "18px", marginRight: "15px" }}
                        />
                      }
                    />
                  </AutoComplete>
                  {/* <Autocomplete
                    getItemValue={(item) => item.label}
                    items={[
                      { label: "apple" },
                      { label: "banana" },
                      { label: "pear" },
                    ]}
                    renderItem={(item, isHighlighted) => (
                      <div
                        style={{
                          background: isHighlighted ? "lightgray" : "white",
                        }}
                      >
                        {item.label}
                      </div>
                    )}
                    value={value}
                    onChange={(e) => (value = e.target.value)}
                    onSelect={(val) => (value = val)}
                  /> */}
                </li>

                <li className="getstart">
                  {user ? (
                    <div className="dropdown">
                      <button
                        className="btn btn-primary dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                      >
                        <div className="headerProfile">
                          <div className="headerProfilePic">
                            {/* <img src="/assets/img/coach01.png" alt="" /> */}
                            {studentProfile?.profile_image ? (
                              <img
                                src={baseurl + studentProfile?.profile_image}
                                alt=""
                              />
                            ) : (
                              <img src="/assets/img/dummy-user.png" alt="" />
                            )}
                          </div>
                          <div>
                            <p className="userName">{studentProfile?.name} </p>
                            <i className="fa fa-angle-down profile_down_arrow"></i>
                          </div>
                        </div>
                      </button>
                      <ul className="dropdown-menu">
                        {/* <li><a href="#">student</a></li> */}
                        <li>
                          <Link to={"/profile"}>profile</Link>
                        </li>
                        <li>
                          <a onClick={logout}>log out</a>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="dropdown mob_pt-5">
                      <button
                        className="btn btn-primary dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                      >
                        get started
                        <i className="fa fa-angle-down"></i>
                      </button>
                      <ul className="dropdown-menu">
                        {/* <li><a href="#">student</a></li> */}
                        <li>
                          <Link to={"/register"}>student</Link>
                        </li>
                        <li>
                          <a
                            href={`${URL.ADMIN_BASE_URL}/login`}
                            target="_blank"
                          >
                            counsellor / mentor
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>

                <li className="text-center">
                  <div
                    className="position-relative mr-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setNotificationSideBar((prev: any) => !prev)}
                  >
                    <i
                      className="fas fa-bell"
                      style={{ fontSize: "22px", color: "#3c4b63" }}
                    ></i>

                    <span
                      style={{
                        background: "#ffffff",
                        fontSize: "10px",
                        right: "-5px",
                        top: "0px",
                        position: "absolute",
                      }}
                      className="translate-middle badge rounded-pill"
                    >
                      {notificationLength}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <header
        className="header"
        style={{ display: SplitUrl === "/top-universities-in-" ? "none" : "" }}
      >
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-2 logo-wrap">
              <div className="main-logo">
                <Link to={"/"}>
                  <img
                    className="scale scale-m-w"
                    src="/assets/img/Admissfy_Logo.png"
                    alt="logo"
                  />{" "}
                </Link>
                {/* <a href="index.html"><img className="scale" src="assets/img/logonews1.png" alt="logo" /></a> */}
              </div>
            </div>
            <div className="tg-navigationarea col-lg-auto order-lg-2">
              <nav id="tg-nav" className="tg-nav">
                <div className="navbar-header mobnav">
                  <button
                    className="menu-toggle btn-open first"
                    ref={anchorRef2}
                    onClick={(e) => togglehendle(true)}
                  >
                    <span></span>
                  </button>
                </div>
                <div
                  id="tg-navigation"
                  className="collapse navbar-collapse tg-navigation main-menu"
                >
                  <ul>
                    <li className={splitLocation[1] === "" ? "active" : ""}>
                      {/* <a href="index.html"><span>home</span></a> */}
                      <Link to={"/"}>
                        <span>home</span>
                      </Link>
                    </li>
                    <li
                      className={
                        splitLocation[1] === "universities"
                          ? "active"
                          : splitLocation[1] === "university"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to={"/universities"}>
                        <span>universities</span>
                      </Link>
                      {/* <a href="#"><span>universities</span></a> */}
                    </li>
                    <li className="menu-item-has-mega-menu">
                      <a href="#" onClick={(e: any) => e.preventDefault()}>
                        <span onClick={() => showMenu()}>
                          courses <i className="fa fa-angle-down"></i>
                        </span>
                      </a>
                      <div className="mega-menu tg-fullmegamenu">
                        <ul className="mega-menu-row">
                          <li className="mega-menu-col">
                            <div className="tg-themetabs">
                              <ul
                                className="tg-themetabnav"
                                role="tablist"
                                style={{ textTransform: "capitalize" }}
                              >
                                {filtered &&
                                  filtered?.map((data: any, i: any) => {
                                    return (
                                      <li
                                        key={i}
                                        /*  role="presentation" */ className={
                                          courseFil == data?.stream_name
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        <a
                                          onMouseOver={() =>
                                            hendlestream(data?.stream_name)
                                          }
                                          className={
                                            courseFil == data?.stream_name
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          {data?.stream_name}
                                        </a>

                                        {courseFil == data?.stream_name ? (
                                          <div className="tab-content tg-themetabcontent">
                                            <div
                                              role="tabpanel"
                                              className="tab-pane tg-tabpane active tab-section"
                                              id="tg-medical"
                                            >
                                              <div className="menu_without_img">
                                                <div className="row">
                                                  <div className="col-sm-6 col-md-6">
                                                    <h4>
                                                      <b>bachelor</b>
                                                    </h4>
                                                    <ul className="headerulStream">
                                                      {courseUg &&
                                                        courseUg?.map(
                                                          (
                                                            data: any,
                                                            index: any
                                                          ) => {
                                                            return (
                                                              <li key={index}>
                                                                <a
                                                                  onClick={() => {
                                                                    let level =
                                                                      data?.level ==
                                                                      1
                                                                        ? "ug"
                                                                        : data?.level ==
                                                                          2
                                                                        ? "pg"
                                                                        : "";
                                                                    history.push(
                                                                      "/course/" +
                                                                        data?.code +
                                                                        "/" +
                                                                        level,
                                                                      {
                                                                        course_id:
                                                                          data?.code,
                                                                        level:
                                                                          data?.level,
                                                                      }
                                                                    );
                                                                    dispatch(
                                                                      UniversityCourseAction.getCourseDetails(
                                                                        {
                                                                          CourseSlug:
                                                                            data?.code,
                                                                          Level:
                                                                            data?.level,
                                                                        }
                                                                      )
                                                                    );
                                                                    closeMenu();
                                                                    return () => {};
                                                                  }}
                                                                >
                                                                  {data?.title}
                                                                </a>
                                                              </li>
                                                            );
                                                          }
                                                        )}
                                                    </ul>
                                                  </div>

                                                  <div className="col-sm-6 col-md-6 ">
                                                    <h4>
                                                      <b>master</b>
                                                    </h4>
                                                    <ul className="headerulStream">
                                                      {coursePg?.map(
                                                        (
                                                          data: any,
                                                          index: any
                                                        ) => {
                                                          return (
                                                            <li key={index}>
                                                              <a
                                                                onClick={() => {
                                                                  let level =
                                                                    data?.level ==
                                                                    1
                                                                      ? "ug"
                                                                      : data?.level ==
                                                                        2
                                                                      ? "pg"
                                                                      : "";
                                                                  history.push(
                                                                    "/course/" +
                                                                      data?.code +
                                                                      "/" +
                                                                      level,
                                                                    {
                                                                      course_id:
                                                                        data?.code,
                                                                      level:
                                                                        data?.level,
                                                                    }
                                                                  );
                                                                  dispatch(
                                                                    UniversityCourseAction.getCourseDetails(
                                                                      {
                                                                        CourseSlug:
                                                                          data?.code,
                                                                        Level:
                                                                          data?.level,
                                                                      }
                                                                    )
                                                                  );
                                                                  closeMenu();
                                                                  return () => {};
                                                                }}
                                                              >
                                                                {data?.title}
                                                              </a>
                                                            </li>
                                                          );
                                                        }
                                                      )}
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li
                      className={
                        splitLocation[1] === "services"
                          ? "active menu-item-has-mega-menu"
                          : " menu-item-has-mega-menu service-dropdown-main"
                      }
                    >
                      <a href="#" onClick={(e: any) => e.preventDefault()}>
                        <span onClick={() => showMenu()}>
                          services <i className="fa fa-angle-down"></i>
                        </span>
                      </a>
                      <div className="mega-menu tg-fullmegamenu">
                        <ul className="mega-menu-row service-dropdown">
                          <li>
                            <a href={"https://www.admissify.com/in/services/"}>
                              Service Details
                            </a>
                          </li>
                          <li>
                            <a
                              href={
                                "https://www.admissify.com/in/career-guidance/"
                              }
                            >
                              Career Guidance
                            </a>
                          </li>
                          <li>
                            <a
                              href={
                                "https://www.admissify.com/in/soft-skill-development/"
                              }
                            >
                              Soft Skill Development
                            </a>
                          </li>
                          <li>
                            <a
                              href={
                                "https://www.admissify.com/in/club-admissify/"
                              }
                            >
                              Club Admissify
                            </a>
                          </li>
                          <li>
                            <a
                              href={
                                "https://www.admissify.com/in/psychometiric-test/"
                              }
                            >
                              Psychometric Test
                            </a>
                          </li>
                          <li>
                            <a
                              href={
                                "https://www.admissify.com/in/profile-building/"
                              }
                            >
                              Profile Building
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li
                      className={
                        splitLocation[1] === "services"
                          ? "active menu-item-has-mega-menu"
                          : " menu-item-has-mega-menu service-dropdown-main"
                      }
                    >
                      <a href="#" onClick={(e: any) => e.preventDefault()}>
                        <span onClick={() => showMenu()}>
                          Countries <i className="fa fa-angle-down"></i>
                        </span>
                      </a>

                      <div className="mega-menu tg-fullmegamenu">
                        <ul
                          className=" service-dropdown"
                          style={{ maxHeight: "500px", overflowY: "scroll" }}
                        >
                          {countries.length > 0 &&
                            countries.map((item: any, index: any) => {
                              return (
                                <li key={index}>
                                  <a
                                    href={item?.rediect}
                                    className="d-flex  align-items-center gap-2"
                                  >
                                    <img width={25} src={item?.flag} />{" "}
                                    &nbsp;&nbsp;&nbsp;
                                    {item?.country_name}
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </li>
                    <li
                      className={
                        splitLocation[1] === "services"
                          ? "active menu-item-has-mega-menu"
                          : " menu-item-has-mega-menu service-dropdown-main"
                      }
                    >
                      <a href="#" onClick={(e: any) => e.preventDefault()}>
                        <span onClick={() => showMenu()}>
                          blogs <i className="fa fa-angle-down"></i>
                        </span>
                      </a>
                      <div className="mega-menu tg-fullmegamenu">
                        <ul className="mega-menu-row service-dropdown">
                          <li>
                            <a
                              href={
                                "https://www.admissify.com/blog/category/study-abroad/"
                              }
                            >
                              Study Abroad
                            </a>
                          </li>
                          <li>
                            <a
                              href={
                                "https://www.admissify.com/blog/category/study-in-india/"
                              }
                            >
                              Study In India
                            </a>
                          </li>
                          <li>
                            <a
                              href={
                                "https://www.admissify.com/blog/category/test-prep/"
                              }
                            >
                              Test Preparation
                            </a>
                          </li>
                          <li>
                            <a
                              href={
                                "https://www.admissify.com/blog/category/upskilling/"
                              }
                            >
                              Upskilling
                            </a>
                          </li>
                          {/* <li>
                            <a
                              href={
                                "https://www.admissify.com/in/psychometiric-test/"
                              }
                            >
                              Psychometric Test
                            </a>
                          </li>
                          <li>
                            <a
                              href={
                                "https://www.admissify.com/in/profile-building/"
                              }
                            >
                              Profile Building
                            </a>
                          </li>
                          <li>
                            <a
                              href={
                                "https://www.admissify.com/in/soft-skill-development/"
                              }
                            >
                              Soft Skill Development
                            </a>
                          </li> */}
                        </ul>
                      </div>
                    </li>
                    {/* <li
                      className={
                        splitLocation[1] === "services"
                          ? "active menu-item-has-children"
                          : " menu-item-has-children"
                      }
                    >
                      <Link to={"/services"}>
                        <span>services</span>
                      </Link>
                    </li> */}

                    <li
                      className={
                        splitLocation[1] === "discussions"
                          ? "active menu-item-has-children"
                          : splitLocation[1] === "about-discussion"
                          ? "active menu-item-has-children"
                          : " menu-item-has-children"
                      }
                    >
                      {/* <a href="javascript:void(0);"><span>discussions</span></a> */}

                      <Link to={"/discussions"}>
                        <span>discussions</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div
        className="close_btn"
        style={{ display: toggelOpne == true ? "block" : "none" }}
        id="closeToggleBar"
      >
        <span onClick={closeSideBar} className="fa fa-times"></span>
      </div>
      <div
        className="zeynep-overlay"
        id="toggleOverlay"
        style={{ display: toggelOpne == true ? "block" : "none" }}
        onClick={closeSideBar}
      ></div>

      {
        <div
          className={toggelOpne == true ? "zeynep opened" : "zeynep"}
          id="toggleBar"
        >
          <div className="side-top-bg">
            <img src="/assets/img/Admissfy_Logo.png" />
          </div>
          <ul>
            {
              <li>
                {/* <a    onClick={(e)=> togglehendle(false)} >home</a> */}
                <Link to={"/"} onClick={(e) => togglehendle(false)}>
                  home
                </Link>
              </li>
            }
            <li>
              <Link to={"/universities"} onClick={(e) => togglehendle(false)}>
                universities
              </Link>
              {/* <a href="/universities">universities</a> */}
            </li>

            <li className="has-submenu">
              <a
                onClick={(e: any) => hendlecouOpneOff("courses")}
                data-submenu="courses"
              >
                courses
              </a>

              <div
                id="courses"
                className={
                  courseOpne == "courses" ? "submenu opened current" : "submenu"
                }
              >
                <div className="submenu-header">
                  <a
                    onClick={(e: any) => hendlecouOpneOff("courses")}
                    data-submenu-close="courses"
                  >
                    courses
                  </a>
                </div>
                <ul>
                  {filtered?.map((data: any, i: any) => {
                    return (
                      <li className="has-submenu" key={i}>
                        <a
                          onClick={(e: any) => hendleOpneOff(data?.stream_name)}
                          data-submenu={"medical" + i}
                        >
                          {data?.stream_name}
                        </a>

                        <div
                          id={"medical" + i}
                          className={
                            streamOpne == data?.stream_name
                              ? "submenu opened current"
                              : "submenu"
                          }
                        >
                          <div className="submenu-header">
                            <a
                              onClick={(e: any) =>
                                hendleOpneOff(data?.stream_name)
                              }
                              data-submenu={"medical" + i}
                              data-submenu-close={"medical" + i}
                            >
                              {data?.stream_name}
                            </a>
                          </div>

                          <label>bachelor</label>
                          <ul>
                            {courseUgOpne &&
                              courseUgOpne?.map((datas: any, index: any) => {
                                return (
                                  <li key={index}>
                                    <a
                                      onClick={() => {
                                        let level =
                                          data?.level == 1
                                            ? "ug"
                                            : data?.level == 2
                                            ? "pg"
                                            : "";
                                        history.push(
                                          "/course/" +
                                            datas?.code +
                                            "/" +
                                            level,
                                          {
                                            course_id: datas?.code,
                                            level: datas?.level,
                                          }
                                        );
                                        closeSideBar();
                                      }}
                                    >
                                      {datas?.title}
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>

                          <label>master</label>
                          <ul>
                            {coursePgOpne?.map((datas: any, index: any) => {
                              return (
                                <li key={index}>
                                  <a
                                    onClick={() => {
                                      let level =
                                        data?.level == 1
                                          ? "ug"
                                          : data?.level == 2
                                          ? "pg"
                                          : "";
                                      history.push(
                                        "/course/" + datas?.code + "/" + level,
                                        {
                                          course_id: datas?.code,
                                          level: datas?.level,
                                        }
                                      );
                                      closeSideBar();
                                    }}
                                  >
                                    {datas?.title}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </li>
            <li className="has-submenu">
              <a
                onClick={(e: any) => hendlecouOpneOff("courses")}
                data-submenu="courses"
              >
                blog
              </a>

              <div
                id="courses"
                className={
                  courseOpne == "courses" ? "submenu opened current" : "submenu"
                }
              >
                <div className="submenu-header">
                  <a
                    onClick={(e: any) => hendlecouOpneOff("courses")}
                    data-submenu-close="courses"
                  >
                    blog
                  </a>
                </div>
                <ul>
                  {Object.keys(blogOptions).map((data: any, i: any) => {
                    return (
                      <li key={i}>
                        <a
                          // onClick={(e: any) => hendleOpneOff(data?.stream_name)}
                          href={blogOptions[data]}
                        >
                          {data}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </li>
            <li className="has-submenu">
              <a
                onClick={(e: any) => hendlecouOpneOff("services")}
                data-submenu="courses"
              >
                services
              </a>

              <div
                id="services"
                className={
                  courseOpne == "services"
                    ? "submenu opened current"
                    : "submenu"
                }
              >
                <div className="submenu-header">
                  <a
                    onClick={(e: any) => hendlecouOpneOff("services")}
                    data-submenu-close="services"
                  >
                    services
                  </a>
                </div>
                <ul>
                  <li>
                    <a href={"https://www.admissify.com/in/services/"}>
                      Service Details
                    </a>
                  </li>
                  <li>
                    <a href={"https://www.admissify.com/in/career-guidance/"}>
                      Career Guidance
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        "https://www.admissify.com/in/soft-skill-development/"
                      }
                    >
                      Soft Skill Development
                    </a>
                  </li>
                  <li>
                    <a href={"https://www.admissify.com/in/club-admissify/"}>
                      Club Admissify
                    </a>
                  </li>
                  <li>
                    <a
                      href={"https://www.admissify.com/in/psychometiric-test/"}
                    >
                      Psychometric Test
                    </a>
                  </li>
                  <li>
                    <a href={"https://www.admissify.com/in/profile-building/"}>
                      Profile Building
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        "https://www.admissify.com/in/soft-skill-development/"
                      }
                    >
                      Soft Skill Development
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li className="has-submenu">
              <a
                onClick={(e: any) => hendlecouOpneOff("services")}
                data-submenu="courses"
              >
                countries
              </a>

              <div
                id="services"
                className={
                  courseOpne == "services"
                    ? "submenu opened current"
                    : "submenu"
                }
              >
                <div className="submenu-header">
                  <a
                    onClick={(e: any) => hendlecouOpneOff("services")}
                    data-submenu-close="services"
                  >
                    countries
                  </a>
                </div>
                <ul>
                  {countries.length > 0 &&
                    countries.map((item: any, index: any) => {
                      return (
                        <li key={index}>
                          <a href={item?.rediect}>{item?.country_name}</a>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </li>

            <li>
              <Link to={"/discussions"} onClick={(e) => togglehendle(false)}>
                discussions
              </Link>
              {/* <a href="/discussions">discussions</a> */}
            </li>
            <li>
              <Link to={"/contact-us"} onClick={(e) => togglehendle(false)}>
                contact
              </Link>
              {/* <a href="/discussions">discussions</a> */}
            </li>
          </ul>
        </div>
      }
      <WelComeForm />
    </>
  );
}

export default Header;
