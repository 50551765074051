import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import api from "./redux/common/api";
import { toast } from "react-toastify";
const firebaseConfig = {
  apiKey: "AIzaSyAOTOPJbfKN018_eScDq_BpG_nSh0uVA0Q",
  authDomain: "admissifynotify.firebaseapp.com",
  databaseURL: "https://admissifynotify-default-rtdb.firebaseio.com",
  projectId: "admissifynotify",
  storageBucket: "admissifynotify.firebasestorage.app",
  messagingSenderId: "623914891701",
  appId: "1:623914891701:web:197ff4fc094578e4eb01fc",
  measurementId: "G-QJT6TTJEN5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const generateDeviceToken = () => {
  let deviceToken = localStorage.getItem("device_token");
  if (!deviceToken) {
    deviceToken = `${Date.now()}-${Math.random()
      .toString(36)
      .substring(2, 15)}`;
    localStorage.setItem("device_token", deviceToken);
  }

  return deviceToken;
};

// Foreground Notifications
// onMessage(messaging, (payload) => {
//   var audio = new Audio("/notify.wav");
//   audio.play();

//   console.log("Foreground Notification Received:", payload);
//   toast.info(`🔔 ${payload.notification.title}\n${payload.notification.body}`);
// });

// Function to request permission and get token
export const requestNotificationPermission = async () => {
  try {
    const token = localStorage.getItem("access_token");

    if (!token) {
      return;
    }
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BFX_TSLrcUbPUr8-S87EibDgrpgAwrkE_fcWQs6kSYf5lLQPR6frOb9ylzHJ17Mn23niccu2YrWZ7GGTt-SdjAU",
      });

      // console.log("FCM Token:", token);
      const deviceToken = generateDeviceToken();
      // Send token to backend
      await api.post("/api/student/setfcntoken", {
        fcnToken: token,
        device: deviceToken,
      });

      return token;
    } else {
      console.warn("Notification permission denied");
    }
  } catch (error) {
    console.error("Error getting notification permission:", error);
  }
};

export { messaging };
