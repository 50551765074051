import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import Swal from "sweetalert2";
import {
  LOADING,
  APPLICANTADD,
  GET_ALL_APLICANT,
  APPLY_NOW,
  GET_APPLICATION_LIST,
  RE_APPLY_NOW,
} from "../common/constant";

const loading = (data: any) => {
  return { type: LOADING, payload: data };
};
const applicantAddAction = (data: any) => {
  return { type: APPLICANTADD, payload: data };
};
const getallapplicantAction = (data: any) => {
  return { type: GET_ALL_APLICANT, payload: data };
};
const applyNowAction = (data: any) => {
  return { type: APPLY_NOW, payload: data };
};

const reapplyNowAction = (data: any) => {
  return { type: RE_APPLY_NOW, payload: data };
};

const getapplicationlistAction = (data: any) => {
  return { type: GET_APPLICATION_LIST, payload: data };
};

export const applicantAdd = (data: any, history: any) => {
  return async (dispatch: any) => {
    dispatch(loading(true));
    try {
      const response: any = await api.post(`${URL.applicantAdd}`, data);
      dispatch(applicantAddAction(response.data?.data));
      // dispatch(loading(false));
      toast.success(response?.data?.message);
      window.location.reload();
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const applyNow = (data: any, history: any) => {
  return async (dispatch: any) => {
    // dispatch(loading(true));
    try {
      const response: any = await api.post(`${URL.applyNow}`, data);
      dispatch(applyNowAction(response.data));
      // dispatch(loading(false));
      // history.push("/login");
      if (response?.data?.status) {
        Swal.fire({
          title: "Application successful",
          confirmButtonColor: "#047475",
          // text: "That thing is still around?",
          icon: "success",
        }).then((response) => {
          console.log(response, "status");

          if (response?.isConfirmed) {
            // alert("testing done")
            // history.push('/profile/appointments')
            history.push("/profile/applications");
          }
        });
        // window.location.href = "/thank-you-apply"
      }
    } catch (error: any) {
      // dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const reApplyNow = (data: any, history: any) => {
  return async (dispatch: any) => {
    dispatch(loading(true));
    try {
      const response: any = await api.post(`${URL.reapplyNow}`, data);
      dispatch(reapplyNowAction(response.data));

      // history.push("/login");
      if (response?.data?.status) {
        history.push("/thank-you-apply");
      }
      dispatch(loading(false));
    } catch (error: any) {
      // dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getallapplicant = (data: any) => {
  return async (dispatch: any) => {
    dispatch(loading(true));
    try {
      const response: any = await api.post(`${URL.getallapplicant}`, data);
      dispatch(getallapplicantAction(response.data?.data));
      dispatch(loading(false));
      // history.push("/login");
      // toast.success(response?.data?.message);
    } catch (error: any) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getapplicationlist = (data: any) => {
  return async (dispatch: any) => {
    dispatch(loading(true));
    try {
      const response: any = await api.get(`${URL.applicationList}?id=${data}`);
      dispatch(getapplicationlistAction(response.data?.data));
      dispatch(loading(false));
      // history.push("/login");
      // toast.success(response?.data?.message);
    } catch (error: any) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
