import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DiscussionAction,
  studentAction,
  scholershipAndOfferAction,
} from "../../redux/common/action";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import dateFormat from "dateformat";
import { URL } from "../../redux/common/url";
import "../../assets/css/profile.css";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

function Discussion({ fileds }:any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modelData, setmodelData] = useState<any>("");
  const [Discussion, setDiscussion] = useState<any>("");

  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const [readMore, setreadMore] = useState<any>(10);

  const [CategoryParentId, setCategoryParentId] = useState<any>("");
  const studentDiscussion: any = useSelector((state: any) =>
    state.student.studentDiscussion ? state.student.studentDiscussion : []
  );

  const getDiscussionCategory: any[] = useSelector((state: any) =>
    state.discussion.getDiscussionCategory
      ? state.discussion.getDiscussionCategory
      : []
  );
  const activegetDiscussionCategory =
    getDiscussionCategory &&
    getDiscussionCategory?.filter((data: any) => {
      return data?.status == 1;
    });

  const baseurl = URL.API_BASE_URL;

  const studentProfile: any = useSelector((state: any) =>
    state.student.getstudentById ? state.student.getstudentById : []
  );

  const getOFferAndScholar: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.getOFferAndScholar
      ? state.scholershipAndOffer.getOFferAndScholar
      : []
  );
  const opneModel = (e: any) => {
    const modeldata =
      getOFferAndScholar &&
      getOFferAndScholar?.filter((data: any) => {
        return data?.id == e;
      });

    setmodelData(modeldata);
  };
  const hendleApplyNow = (e: any) => {
    if (isAuthenticated == false) {
      history.push("/login", { logback: 1 });
    } else {
      history.push("/");
    }
  };

  $("#DiscussionBtn").click(function (k, v) {
    $("#discussionForm").fadeIn();
    $("#ReadTextBtn").fadeOut();
    $("#DiscussionBtn").fadeOut();
  });

  useEffect(() => {
    var Data = { id: user?.id, mobile: user?.mobile };
    dispatch(studentAction.studentDiscussion(Data, user?.id));
    return () => { };
  }, []);

  useEffect(() => {
    dispatch(scholershipAndOfferAction.getOffersAndScholar({}));
    return () => { };
  }, []);

  useEffect(() => {
    dispatch(DiscussionAction.getDisCategoryList({}));
    return () => { };
  }, []);

  const handleReview = (e: any, type = null) => {
    if (type === "category") {
      setCategoryParentId(e.target.value);
    }
    const { name, value } = e.target;
    setDiscussion({
      ...Discussion,
      [name]: value,
      user_id: user?.id,
      user_type: 2,
      auther_name: studentProfile?.name,
    });
  };
  const submitDiscussion = (e: any) => {
    const Category = $("#category_id").val();
    const SubCategory = $("#sub_category_id").val();
    const Topic = $("#topic").val();
    const Question = $("#description").val();
    $(".error-class").remove();
    var isError = false;
    if (!Category) {
      $("#category_id").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (!SubCategory) {
      $("#sub_category_id").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (!Topic.trim()) {
      $("#topic").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (!Question) {
      $("#description").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (isError) {
      return false;
    }
    dispatch(DiscussionAction.addDiscussion(Discussion));
    window.location.reload();
  };

  return (
    <div className="col-md-12 col-lg-9">
      <div className="row">
        <div className="col-md-5 col-lg-3 order-lg-3 tab_offer">
          <div className="page-sidebar">
            {getOFferAndScholar &&
              getOFferAndScholar?.map((data: any, i: any) => {
                return (
                  i < 2 && (
                    <div className="widget sidebar-title ads-blog">
                      <div key={i}>
                        <div className="offers-item_ yello title">
                          <a
                            onClick={(e) => {
                              data?.type == "Offers"
                                ? opneModel(data?.id)
                                : history.push(
                                  "/scholarships/" + data?.sch_slug
                                );
                            }}
                            data-toggle="modal"
                            data-target="#offer-pop"
                          >
                            {data?.type == "Offers" ? (
                              data?.coupon_icon !== null ? (
                                <img
                                  className="scale"
                                  src={baseurl + data?.coupon_icon}
                                  onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src = "/assets/img/noImage.jpeg";
                                  }}
                                />
                              ) : (
                                <img
                                  className="scale"
                                  src="/assets/img/noImage.jpeg"
                                />
                              )
                            ) : data?.sch_image !== null ? (
                              <img
                                className="scale"
                                src={baseurl + data?.sch_image}
                                onError={(e: any) => {
                                  e.target.onerror = null;
                                  e.target.src = "/assets/img/noImage.jpeg";
                                }}
                              />
                            ) : (
                              <img
                                className="scale"
                                src="/assets/img/noImage.jpeg"
                              />
                            )}
                          </a>
                          <figcaption className="content-box offerHeight_">
                            <a
                              className="margin_auto"
                              onClick={(e) => {
                                data?.type == "Offers"
                                  ? opneModel(data?.id)
                                  : history.push(
                                    "/scholarships/" + data?.sch_slug
                                  );
                              }}
                              data-toggle="modal"
                              data-target="#offer-pop"
                            >
                              <h5>
                                {data?.type == "Offers" && (
                                  <span>
                                    {data?.coupon_type == 1
                                      ? "Get " + data?.max_off + "%"
                                      : "Get " + data?.max_off}{" "}
                                    OFF
                                  </span>
                                )}
                                {data?.type == "Offers"
                                  ? data?.coupon_title?.length > 16
                                    ? `${data?.coupon_title.substring(0, 15)}.`
                                    : data?.coupon_title
                                  : data?.sch_title?.length > 40
                                    ? `${data?.sch_title.substring(0, 40)}.`
                                    : data?.sch_title}
                              </h5>
                            </a>
                          </figcaption>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        </div>
        <div className="col-md-12 col-lg-9">
          <div className="contentbx shade1 bg-white application_wrap h-100">
            <section className="categories">
              <div className="col-md-12 col-lg-12">
                <div className="appli-t">
                  <h1>discussion</h1>
                  <img src="/assets/img/appoint--icon.svg" />
                </div>
                <div id="discussionPage1">
                  <div className="col-md-12 col-lg-12 text-right">
                    <a className="text_hyper" id="DiscussionBtn">
                      <i className="fa fa-plus"></i> Add Discussion
                    </a>
                  </div>
                  <div className="row mt-2   ReadTextBtn" id="ReadTextBtn">
                    {studentDiscussion.length == 0 ? (
                      <div className="col-lg-12 p-2">
                        <div className="appli-box shade1">
                          <div className="col-md-12 appli-box-t">
                            <p>No discussion found!</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {studentDiscussion.length > 0 &&
                          studentDiscussion?.map((data: any, i: any) => {
                            let appliedDate =
                              data?.created_at &&
                              dateFormat(data?.created_at, "d-m-yyyy");
                            return (
                              <div className="col-lg-6 col-md-6 col-sm-12 my-3 ">
                                <div
                                  className=" d-flex flex-column shadow mx-0  p-2 "
                                  style={{ width: "100%", height: "100%" }}
                                >
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={
                                      "/about-discussion/" + data?.slug + ""
                                    }
                                    style={{ cursor: "pointer" }}
                                    className=" mt-1"
                                  >
                                    <b> {data?.topic}</b>
                                  </a>
                                  <p className="text-secondary my-1">
                                    {appliedDate}
                                  </p>
                                  <p className="text-primary">
                                    <i className="fa fa-tag"></i>
                                    <b> {data?.category?.category_name}</b>
                                  </p>

                                  {data?.description ? (
                                    data?.description?.split(" ").length >
                                      readMore ? (
                                      <p className="course__font">
                                        {" "}
                                        {data?.description
                                          ?.split(" ")
                                          .splice(0, 10)
                                          .join(" ")}
                                        .{" "}
                                        <a
                                          className="read-more-new"
                                          onClick={() =>
                                            setreadMore(
                                              data?.description?.split(" ")
                                                .length &&
                                              data?.description?.split(" ")
                                                .length + 1
                                            )
                                          }
                                        >
                                          read more
                                        </a>{" "}
                                      </p>
                                    ) : (
                                      <p className="text-lead">
                                        {data?.description}
                                      </p>
                                    )
                                  ) : (
                                    <p></p>
                                  )}

                                  <div className="d-flex mt-2 dis-like-icon">
                                    <i className="fa fa-thumbs-up text-warning px-1">
                                      <span className="text-dark px-1">
                                        {data?.likes ? data?.likes : 0}
                                      </span>
                                    </i>
                                    <i className="fa fa-comment-o text-warning px-1">
                                      <span className="text-dark px-1">
                                        {data?.DiscussionsComments?.length}
                                      </span>
                                    </i>
                                    <i className="fa fa-eye text-warning px-1">
                                      <span className="text-dark px-1">
                                        {data?.views ? data?.views : 0}
                                      </span>
                                    </i>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </>
                    )}
                  </div>
                </div>

                <div className="col-md-12 " id="discussionForm">
                  <div className="profile_field fields-wrp  discussion-form">
                    <form id="discussion" className="row">
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group">
                          <select
                            className="form-control c-select"
                            name="category_id"
                            required
                            value={Discussion?.category_id}
                            id="category_id"
                            onChange={(e) => {
                              handleReview(e, "category");
                            }}
                          >
                            <option value="">category</option>
                            {activegetDiscussionCategory &&
                              activegetDiscussionCategory?.map(
                                (data: any, i: any) => {
                                  if (data?.parent_id == 0)
                                    return (
                                      <option value={data?.id}>
                                        {data?.category_name}
                                      </option>
                                    );
                                }
                              )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-6">
                        <div className="form-group">
                          <select
                            required
                            className="form-control c-select"
                            name="sub_category_id"
                            value={Discussion?.sub_category_id}
                            id="sub_category_id"
                            onChange={(e) => {
                              handleReview(e);
                            }}
                          >
                            <option value="">sub category</option>
                            {activegetDiscussionCategory &&
                              activegetDiscussionCategory?.map(
                                (data: any, i: any) => {
                                  if (data?.parent_id != 0)
                                    return (
                                      <option
                                        style={{
                                          display:
                                            CategoryParentId == data?.parent_id
                                              ? "block"
                                              : "none",
                                        }}
                                        value={data?.id}
                                      >
                                        {data?.category_name}
                                      </option>
                                    );
                                }
                              )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-12">
                        <div className="form-group field">
                          <input
                            type="text"
                            className="form-control c-select"
                            name="topic"
                            value={Discussion?.topic}
                            id="topic"
                            onChange={(e) => {
                              handleReview(e);
                            }}
                          />
                          <label>topic</label>
                          <span className="fa fa-pencil p-2"></span>
                        </div>
                      </div>

                      <div className="col-md-12 col-lg-12">
                        <div className="form-group">
                          <textarea
                            className="form-control c-select"
                            placeholder="details"
                            name="description"
                            value={Discussion?.description}
                            id="description"
                            onChange={(e) => {
                              handleReview(e);
                            }}
                            rows="7"
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-md-12 col-lg-12">
                        <div className="fieldbtn text-right pt-3">
                          <input
                            type="button"
                            onClick={(e) => {
                              submitDiscussion("discussion");
                            }}
                            className="bggreadent discussion_btn"
                            value="submit"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div
        id="offer-pop"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <figure className="feat-text">
                  {modelData[0]?.type == "Offers" ? (
                    modelData[0]?.coupon_icon !== null ? (
                      <img
                        className="scale homeOffer"
                        src={baseurl + modelData[0]?.coupon_icon}
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src = "/assets/img/noImage.jpeg";
                        }}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img
                        className="scale homeOffer"
                        src="/assets/img/noImage.jpeg"
                      />
                    )
                  ) : modelData[0]?.sch_image !== null ? (
                    <img
                      className="scale homeOffer"
                      src={baseurl + modelData[0]?.sch_image}
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.src = "/assets/img/noImage.jpeg";
                      }}
                    />
                  ) : (
                    <img
                      className="scale homeOffer"
                      src="/assets/img/noImage.jpeg"
                    />
                  )}
                </figure>
                <figcaption className="content-box">
                  <h3>
                    {modelData[0]?.type == "Offers" ? "Get" : ""}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {modelData[0]?.coupon_type == 1
                          ? modelData[0]?.max_off + "%"
                          : modelData[0]?.max_off}{" "}
                        OFF
                      </span>
                    )}
                    {modelData[0]?.type == "Offers"
                      ? modelData[0]?.coupon_title?.length > 16
                        ? `${modelData[0]?.coupon_title.substring(0, 15)}.`
                        : modelData[0]?.coupon_title
                      : modelData[0]?.sch_title?.length > 40
                        ? `${modelData[0]?.sch_title.substring(0, 40)}.`
                        : modelData[0]?.sch_title}
                    {/* Study in Abroad */}
                  </h3>
                  <div className="bttns">
                    <a href="" className="tnc">
                      t&amp;c apply
                    </a>
                  </div>
                </figcaption>
                <figcaption className="details-box p-3">
                  <p className="text-left">
                    Coupon Code:{" "}
                    {modelData[0]?.type == "Offers" && (
                      <span>{modelData[0]?.coupon_code}</span>
                    )}{" "}
                  </p>
                  <p className="text-right">
                    exp:{" "}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {moment(modelData[0]?.valid_from).format("DD-MM-YYYY")}
                      </span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.apply_end_date}.`
                      : ""}
                  </p>
                  <p className="text-left">
                    {/* {modelData[0]?.description} */}
                    {modelData[0]?.type == "Offers" && (
                      <span>{modelData[0]?.description}</span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.short_desc}.`
                      : ""}
                  </p>
                  <div className="bttns text-right">
                    <a onClick={hendleApplyNow} className="applybtn bggreadent">
                      apply now
                    </a>
                  </div>
                </figcaption>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Discussion;
