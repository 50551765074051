import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UniversityAction } from "../../redux/common/action";
import { Link, useHistory, useLocation } from "react-router-dom";
import Slider from "react-slick";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import { URL } from "../../redux/common/url";

function TrendingUniversites() {
  const dispatch = useDispatch();
  const lastLocation = useLocation();
  const history = useHistory();
  const baseurl = URL.API_BASE_URL;
  const [like, setLike] = useState(false);
  const university: any[] = useSelector((state: any) =>
    state.unversity.universityList ? state.unversity.universityList : []
  );

  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);

  const trendingUniversities: any[] = useSelector((state: any) =>
    state.unversity.universityHome.data
      ? state.unversity.universityHome.data
      : []
  );
  const universityfilter = university?.filter((datas: any) => {
    return datas?.status == 1;
  });

  var countryId = [];

  var userInterests = localStorage.getItem("userInterest");
  if (userInterests) {
    userInterests = JSON.parse(userInterests);
    if (user && userInterests != null) {
      userInterests[0]?.country_id?.map((data: any, i: any) => {
        countryId.push(data.countryId);
      });
    }
  }

  const uniLike: any = useSelector((state: any) =>
    state.unversity.uniLike ? state.unversity.uniLike : []
  );
  const uniLikeUnlike: any = useSelector((state: any) =>
    state.unversity.uniLikeDislike ? state.unversity.uniLikeDislike : []
  );
  var favUniversity: any[] = [];
  if (uniLike) {
    uniLike.map((v: any, k: any) => {
      favUniversity.push(v.university_id);
    });
  }
  const hendleLike = (e: any) => {
    var Data = {
      id: user?.id,
      student_id: user?.id,
      university_id: e,
      mobile: user?.mobile,
    };
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      dispatch(UniversityAction.universityLikeDislike(Data, user?.id));
    }
  };

  useEffect(() => {
    var Data = { id: user?.id, mobile: user?.mobile };
    dispatch(UniversityAction.universityLike(Data, user?.id));
    return () => {};
  }, [uniLikeUnlike]);

  useEffect(() => {
    dispatch(
      UniversityAction.getTrendingUniversitiesHome({
        search_by: "",
        offset: 0,
        country_id: [],
        is_trending: 1,
        // search_by: '', offset: 0, country_id: countryId ? countryId : [], is_trending: 1,
        limit: 10,
      })
    );
    return () => {};
  }, [uniLikeUnlike]);

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div>
        <section className="categories section iPad-mt">
          <div className="container">
            <div className="headingmains text-center">
              <h2 className="titlewithline right aos-init">
                Trending Universities{" "}
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="43.032"
                    height="35.894"
                    viewBox="0 0 43.032 35.894"
                  >
                    <path
                      id="mortarboard"
                      d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z"
                      transform="translate(0 -42.469)"
                      fill="#fbb415"
                    />
                  </svg>
                </span>
              </h2>
              <p className="text-center pl-2 pr-2">
                Get enrollment to your preferred university abroad. Download
                admissify app and learn about all the prestigious universities
                around the world. Study hard, Fly high.
              </p>
            </div>
          </div>
          <div className="all-center sub-section pb-0">
            <div className="col-md-12 all-center pb-5">
              <div className="uni_slide">
                <Slider {...settings}>
                  {trendingUniversities &&
                    trendingUniversities?.map((data: any, index: any) => {
                      const banners =
                        typeof data?.UniversityDetail?.banners == "string"
                          ? JSON.parse(data?.UniversityDetail?.banners)
                          : "";
                      return (
                        <div key={index}>
                          <div className="featured-item">
                            <div className="feat-img">
                              {data?.UniversityDetail?.banners !== null ? (
                                <img
                                  src={baseurl + banners}
                                  alt=""
                                  className="scale trandingUniImg"
                                  onClick={() => {
                                    history.push("/university/" + data?.slug, {
                                      university_id: data?.id,
                                    });
                                  }}
                                />
                              ) : (
                                <img
                                  src="/assets/img/noImage.jpeg"
                                  alt=""
                                  className="scale trandingUniImg"
                                />
                              )}
                              <div className="th-name">
                                {data?.university_logo !== null ? (
                                  <img
                                    src={baseurl + data?.university_logo}
                                    alt=""
                                    className="scale trandingUnilogo"
                                  />
                                ) : (
                                  <img
                                    className="scale trandingUnilogo"
                                    src="/assets/img/noImage.jpeg"
                                    alt=""
                                  />
                                )}
                                <h5
                                  className="homeunire"
                                  onClick={() => {
                                    history.push("/university/" + data?.slug, {
                                      university_id: data?.id,
                                    });
                                  }}
                                >
                                  {data?.university_name}
                                </h5>
                              </div>
                              <div className="overlayPort">
                                <ul
                                  className={
                                    data?.is_partner == 1
                                      ? " is_admi_verified info text-center list-inline"
                                      : " info text-center list-inline"
                                  }
                                >
                                  <li>
                                    {data?.is_partner == 1 ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="43.032"
                                        height="35.894"
                                        viewBox="0 0 43.032 35.894"
                                      >
                                        <path
                                          id="mortarboard"
                                          d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z"
                                          transform="translate(0 -42.469)"
                                          fill="#fbb415"
                                        />
                                      </svg>
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                  <li>
                                    <button
                                      className={
                                        favUniversity.includes(data?.id)
                                          ? "wishlist active"
                                          : "wishlist"
                                      }
                                      onClick={(e) => hendleLike(data?.id)}
                                      type="button"
                                    >
                                      <span>
                                        {" "}
                                        <i className="fa fa-heart"></i>
                                      </span>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </div>
            <div
              className="all-center"
              data-aos="fade-right"
            >
              <span
                onClick={() => {
                  history.push("/universities", {
                    country_id: [],
                    course_id: [],
                    rank: "",
                    marks: "",
                    course_level: "",
                    intake: "",
                    offset: 0,
                    limit: 50,
                    is_trending: 1,
                  });
                }}
                className="view-more"
                style={{ cursor: "pointer" }}
              >
                View all{" "}
              </span>
              {/* <a href="/universitieslist" className="view-more">
              View all
            </a> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default TrendingUniversites;
