import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  counselloerAction,
  coachAction,
  countryAction,
  UniversityAction,
} from "../../redux/common/action";
import { Link, useHistory, useLocation } from "react-router-dom";

import CounsellorList from "./CounsellorList";
import CoachList from "./CoachList";
import Seo from "../Seo/Seo";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import VerticalAddSlider from "../AdSlider/verticalAddSlider";
var arrayId = [];
function CounsellorAndCoach() {
  const history = useHistory();
  const lastLocation = useLocation();
  const [available, setavailable] = useState<any[]>([]);
  const [availableid, setavailableid] = useState<any[]>([]);
  const id: any = useLocation();

  const [fees, setfees] = useState<any[]>([]);
  const [short, setshort] = useState<any[]>([]);
  const [feesid, setfeesid] = useState<any[]>([]);
  const [experience, setexperience] = useState<any[]>([]);
  const [experienceid, setexperienceid] = useState<any[]>([]);
  const [country, setcountry] = useState<any[]>([]);
  const [countryid, setcountryid] = useState<any[]>([]);
  const [title, settitle] = useState<any>("counsellor-and-mentor");
  const [availableOpne, setavailableOpne] = useState<any>(
    isMobile ? true : false
  );
  const [feesOpne, setfeesOpne] = useState<any>(isMobile ? true : false);
  const [experienceOpne, setexperienceOpne] = useState<any>(
    isMobile ? true : false
  );
  const [countryOpne, setcountryOpne] = useState<any>(isMobile ? true : false);
  const [oneTime, setOneTime] = useState<any>("0");

  const hendlecountryOpne = (e: any) => {
    if (countryOpne == true) {
      setcountryOpne(false);
    } else {
      setcountryOpne(true);
    }
  };

  const hendleavailableOpne = (e: any) => {
    if (availableOpne == true) {
      setavailableOpne(false);
    } else {
      setavailableOpne(true);
    }
  };
  const hendlefeesOpne = (e: any) => {
    if (feesOpne == true) {
      setfeesOpne(false);
    } else {
      setfeesOpne(true);
    }
  };
  const hendleexperienceOpne = (e: any) => {
    if (experienceOpne == true) {
      setexperienceOpne(false);
    } else {
      setexperienceOpne(true);
    }
  };

  const [counsellorandcoach, setcounsellorandcoach] =
    useState<any>("counsellor");
  const dispatch = useDispatch();

  const counsellor: any[] = useSelector((state: any) =>
    state.counsellor.counsellorList ? state.counsellor.counsellorList : []
  );
  const countrys: any[] = useSelector((state: any) =>
    state.country.countryList ? state.country.countryList : []
  );

  const coach: any[] = useSelector((state: any) =>
    state.coach.couchList ? state.coach.couchList : []
  );

  const coun = (e: any, data: any) => {
    setcounsellorandcoach(data.data);
    dispatch(
      counselloerAction.setLoading(true)
    )
    setTimeout(() => {
      dispatch(
        counselloerAction.setLoading(false)
      )
    }, 1000)
  };

  const shortBy = (e: any) => {
    setshort(e);
    dispatch(
      counselloerAction.getCounsellorInfo({
        experience: experienceid.length != 0 ? experienceid : "",
        rate_per_hr: feesid.length != 0 ? feesid : "",
        preferred_country: countryid.length != 0 ? countryid : [],
        sort_by: e,
        offset: 0,
        limit: 10,
      })
    );
    dispatch(
      coachAction.getcoachinfo({
        experience: experienceid.length != 0 ? experienceid : "",
        rate_per_hr: feesid.length != 0 ? feesid : "",
        preferred_country: countryid.length != 0 ? countryid : [],
        sort_by: e,
        offset: 0,
        limit: 10,
      })
    );
    return () => {};
  };

  // useEffect(() => {
  //   dispatch(counselloerAction.getAllCounsellor({}));
  //   return () => {};
  // }, []);

  useEffect(() => {
    dispatch(
      counselloerAction.getCounsellorInfo({
        experience: "",
        rate_per_hr: "",
        preferred_country: countryid ? countryid : [],
        sort_by: "",
        offset: 0,
        limit: 500,
      })
    );
    return () => {};
  }, []);

  useEffect(() => {
    if (oneTime == 0) {
      setOneTime(1);
      if (id?.state?.country_id) {
        var countryID = [];
        countryID.push(id?.state?.country_id);
        setcountryid(countryID);
      }
      if (id?.state?.country_name) {
        var countryName = [];
        countryName.push(id?.state?.country_name);
        setcountry(countryName);
      }
    }
    dispatch(
      coachAction.getcoachinfo({
        experience: "",
        rate_per_hr: "",
        preferred_country: countryid ? countryid : [],
        sort_by: "",
        offset: 0,
        limit: 500,
      })
    );
    return () => {};
  }, []);

  const hendlClickAvailable = (e: any) => {
    let array = [...available];
    let arrayid = [...availableid];
    var dataId = $(e.target).attr("dataid");
    if (e.target.checked) {
      array.push(e.target.value);
      arrayid.push(e.target.value);
      arrayId.push(dataId);
      setavailable(array);
      setavailableid(arrayid);
      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: "",
          rate_per_hr: feesid?.length == 0 ? "" : Math.max(...feesid),
          available: arrayId?.length == 0 ? "" : arrayId,
          preferred_country: [],
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: "",
          rate_per_hr: feesid?.length == 0 ? "" : Math.max(...feesid),
          available: arrayId?.length == 0 ? "" : arrayId,
          preferred_country: [],
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 10,
        })
      );
      return () => {};
    } else {
      let coun = array.indexOf(e.target.value);
      let coun1 = arrayId.indexOf(dataId);
      if (coun1 > -1) {
        arrayId.splice(coun1, 1);
      }
      if (coun > -1 || coun1 > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setavailable(array);
        setavailableid(arrayid);
        dispatch(
          counselloerAction.getCounsellorInfo({
            experience: "",
            rate_per_hr: feesid?.length == 0 ? "" : Math.max(...feesid),
            available: arrayId?.length == 0 ? "" : arrayId,
            preferred_country: [],
            sort_by: short?.length == 0 ? "" : short,
            offset: 0,
            limit: 50,
          })
        );
        dispatch(
          coachAction.getcoachinfo({
            experience: "",
            rate_per_hr: feesid?.length == 0 ? "" : Math.max(...feesid),
            available: arrayId?.length == 0 ? "" : arrayId,
            preferred_country: [],
            sort_by: short?.length == 0 ? "" : short,
            offset: 0,
            limit: 10,
          })
        );
        return () => {};
      }
    }
  };

  const remove = (event: any, value: any) => {
    let array = [...available];
    let arrayid = [...availableid];

    let coun = array.indexOf(value);
    var dataId = $('._c_filter[value="' + value + '"]').attr("dataid");

    let coun1 = arrayId.indexOf(dataId);
    if (coun1 > -1) {
      arrayId.splice(coun1, 1);
    }
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setavailable(array);
      setavailableid(arrayid);
      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: "",
          rate_per_hr: feesid?.length == 0 ? "" : Math.max(...feesid),
          available: arrayId?.length == 0 ? "" : arrayId,
          preferred_country: [],
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: "",
          rate_per_hr: feesid?.length == 0 ? "" : Math.max(...feesid),
          available: arrayId?.length == 0 ? "" : arrayId,
          preferred_country: [],
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 10,
        })
      );
    }
  };

  const hendlClickfees = (event: any) => {
    let array = [...fees];
    let arrayid = [...feesid];
    if (event.target.checked) {
      array.push(event.target.value);
      arrayid.push(event.target.name);
      setfees(array);
      setfeesid(arrayid);

      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: "",
          rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          preferred_country: [],
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: "",
          rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          preferred_country: [],
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 10,
        })
      );
      return () => {};
    } else {
      let coun = array.indexOf(event.target.value);
      if (coun > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setfees(array);
        setfeesid(arrayid);
        dispatch(
          counselloerAction.getCounsellorInfo({
            experience: "",
            rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
            preferred_country: [],
            sort_by: short?.length == 0 ? "" : short,
            offset: 0,
            limit: 50,
          })
        );
        dispatch(
          coachAction.getcoachinfo({
            experience: "",
            rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
            preferred_country: [],
            sort_by: short?.length == 0 ? "" : short,
            offset: 0,
            limit: 10,
          })
        );
        return () => {};
      }
    }
  };

  const removefees = (event: any, value: any) => {
    let array = [...fees];
    let arrayid = [...feesid];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setfees(array);
      setfeesid(arrayid);
      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: "",
          rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          preferred_country: [],
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: "",
          rate_per_hr: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          preferred_country: [],
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 10,
        })
      );
      return () => {};
    }
  };

  const hendlClickexperience = (event: any) => {
    let array = [...experience];
    let arrayid = [...experienceid];
    if (event.target.checked) {
      array.push(event.target.value);
      arrayid.push(event.target.name);
      setexperience(array);
      setexperienceid(arrayid);
      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          rate_per_hr: "",
          preferred_country: [],
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          rate_per_hr: "",
          preferred_country: [],
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 10,
        })
      );
      return () => {};
    } else {
      let coun = array.indexOf(event.target.value);

      if (coun > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setexperience(array);
        setexperienceid(arrayid);
        dispatch(
          counselloerAction.getCounsellorInfo({
            experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
            rate_per_hr: "",
            preferred_country: [],
            sort_by: short?.length == 0 ? "" : short,
            offset: 0,
            limit: 50,
          })
        );
        dispatch(
          coachAction.getcoachinfo({
            experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
            rate_per_hr: "",
            preferred_country: [],
            sort_by: short?.length == 0 ? "" : short,
            offset: 0,
            limit: 10,
          })
        );
        return () => {};
      }
    }
  };

  const removeexperience = (event: any, value: any) => {
    let array = [...experience];
    let arrayid = [...experienceid];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setexperience(array);
      setexperienceid(arrayid);

      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          rate_per_hr: "",
          preferred_country: [],
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: arrayid?.length == 0 ? "" : Math.max(...arrayid),
          rate_per_hr: "",
          preferred_country: [],
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 10,
        })
      );
      return () => {};
    }
  };

  const hendlClickCountry = (event: any) => {
    let array = [...country];
    let arrayid = [...countryid];
    if (event.target.checked) {
      array.push(event.target.value);
      arrayid.push(event.target.name);
      setcountry(array);
      setcountryid(arrayid);
      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: "",
          rate_per_hr: "",
          preferred_country: arrayid,
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: "",
          rate_per_hr: "",
          preferred_country: arrayid,
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 10,
        })
      );
      return () => {};
    } else {
      let coun = array.indexOf(event.target.value);
      if (coun > -1) {
        array.splice(coun, 1);
        arrayid.splice(coun, 1);
        setcountry(array);
        setcountryid(arrayid);
        dispatch(
          counselloerAction.getCounsellorInfo({
            experience: "",
            rate_per_hr: "",
            preferred_country: arrayid,
            sort_by: short?.length == 0 ? "" : short,
            offset: 0,
            limit: 50,
          })
        );
        dispatch(
          coachAction.getcoachinfo({
            experience: "",
            rate_per_hr: "",
            preferred_country: arrayid,
            sort_by: short?.length == 0 ? "" : short,
            offset: 0,
            limit: 10,
          })
        );
        return () => {};
      }
    }
  };

  const removecountry = (event: any, value: any) => {
    let array = [...country];
    let arrayid = [...countryid];
    let coun = array.indexOf(value);
    if (coun > -1) {
      array.splice(coun, 1);
      arrayid.splice(coun, 1);
      setcountry(array);
      setcountryid(arrayid);
      dispatch(
        counselloerAction.getCounsellorInfo({
          experience: "",
          rate_per_hr: "",
          preferred_country: arrayid,
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 50,
        })
      );
      dispatch(
        coachAction.getcoachinfo({
          experience: "",
          rate_per_hr: "",
          preferred_country: arrayid,
          sort_by: short?.length == 0 ? "" : short,
          offset: 0,
          limit: 10,
        })
      );
      return () => {};
    }
  };

  return (
    <div>
      <Seo slug={window.location.pathname} />
      <section
        className="headslider simpleslider_wrp"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <div className="sliderbox">
          <div className="slidercont owl-carousel111">
            <div>
              <img
                className="scale"
                src="assets/img/counsellor-listheader.jpg"
                alt=""
              />
              <div className="content simpleslider_head">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div className="textwrp">
                        <h4 className="yellow">
                          <b>counsellor/mentor-listing</b>
                        </h4>
                        <p className="text-white">
                          <b>
                            We are all ears and we love to listen! No matter
                            where you're stuck in the study abroad journey, our
                            counsellor is all yours.
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uni-list-page">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-2 col-md-pull-10"
              // style={{ border: "1px solid red" }}
            >
              <div className="page-sidebar">
                <div className="widget sidebar-title">
                  <h5>
                    {country?.length !== 0 ? (
                      <span>
                        {" "}
                        selected <b>filters</b>{" "}
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    ) : experience?.length !== 0 ? (
                      <span>
                        {" "}
                        selected <b>filters</b>{" "}
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    ) : fees?.length !== 0 ? (
                      <span>
                        {" "}
                        selected <b>filters</b>{" "}
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    ) : available?.length !== 0 ? (
                      <span>
                        {" "}
                        selected <b>filters</b>{" "}
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </span>
                    ) : (
                      <span>
                        {" "}
                        no filter selected
                        <i className="fa fa-angle-up"></i>
                      </span>
                    )}
                  </h5>
                  <ul
                    className={
                      country?.length !== 0
                        ? "clear-filter"
                        : experience?.length !== 0
                        ? "clear-filter"
                        : fees?.length !== 0
                        ? "clear-filter"
                        : available?.length !== 0
                        ? "clear-filter"
                        : "clear-filter filDisNone"
                    }
                  >
                    {country &&
                      country.map((data: any, i: any) => {
                        return (
                          <li key={i}>
                            {data}
                            <a onClick={(e: any) => removecountry(e, data)}>
                              <sup>
                                <i className="fa fa-close"></i>
                              </sup>
                            </a>
                          </li>
                        );
                      })}
                    {experience &&
                      experience.map((data: any, i: any) => {
                        return (
                          <li key={i}>
                            {data}
                            <a onClick={(e: any) => removeexperience(e, data)}>
                              <sup>
                                <i className="fa fa-close"></i>
                              </sup>
                            </a>
                          </li>
                        );
                      })}
                    {fees &&
                      fees.map((data: any, i: any) => {
                        return (
                          <li key={i}>
                            {data}
                            <a onClick={(e: any) => removefees(e, data)}>
                              <sup>
                                <i className="fa fa-close"></i>
                              </sup>
                            </a>
                          </li>
                        );
                      })}
                    {available &&
                      available.map((data: any, i: any) => {
                        return (
                          <li key={i}>
                            {data}
                            <a onClick={(e: any) => remove(e, data)}>
                              <sup>
                                <i className="fa fa-close"></i>
                              </sup>
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>

                {/* <!-- WIDGET --> */}
                <div className="widget widget_has_radio_checkbox">
                  <h5 onClick={hendleavailableOpne}>
                    <span>
                      filter by <b>available</b>{" "}
                      {availableOpne == true ? (
                        <i className="fa fa-angle-up"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>{" "}
                  </h5>
                  <ul className={availableOpne == true ? "countryHide" : ""}>
                    {availableOpne == false ? (
                      <li>
                        <label>
                          <input
                            className="_c_filter"
                            type="checkbox"
                            value="today"
                            dataid="1"
                            checked={available.includes("today") ? true : false}
                            onChange={(e: any) => hendlClickAvailable(e)}
                          />
                          <i className="awe-icon awe-icon-check"></i>
                          today
                        </label>
                      </li>
                    ) : (
                      ""
                    )}
                    {availableOpne == false ? (
                      <li>
                        <label>
                          <input
                            className="_c_filter"
                            type="checkbox"
                            value="tomorrow"
                            dataid="2"
                            checked={
                              available.includes("tomorrow") ? true : false
                            }
                            onChange={(e: any) => hendlClickAvailable(e)}
                          />
                          <i className="awe-icon awe-icon-check"></i>
                          tomorrow
                        </label>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* {availableOpne == false ? (
                      <li>
                        <label>
                          <input
                            className="_c_filter"
                            type="checkbox"
                            value="within week"
                            dataid="3"
                            checked={
                              available.includes("within week") ? true : false
                            }
                            onChange={(e: any) => hendlClickAvailable(e)}
                          />
                          <i className="awe-icon awe-icon-check"></i>
                          within week
                        </label>
                      </li>
                    ) : (
                      ""
                    )} */}
                  </ul>
                </div>
                {/* <!-- END / WIDGET -->

                            <!-- WIDGET --> */}
                <div className="widget widget_has_radio_checkbox">
                  <h5 onClick={hendlefeesOpne}>
                    <span>
                      filter by <b>fees</b>{" "}
                      {feesOpne == true ? (
                        <i className="fa fa-angle-up"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>{" "}
                  </h5>
                  <ul className={feesOpne == true ? "countryHide" : ""}>
                    {feesOpne == false ? (
                      <li>
                        <label>
                          <input
                            name="5000"
                            type="checkbox"
                            value="upto 5000"
                            checked={fees.includes("upto 5000")}
                            onChange={(e: any) => hendlClickfees(e)}
                          />
                          <i className="awe-icon awe-icon-check"></i>
                          upto 5000
                        </label>
                      </li>
                    ) : (
                      " "
                    )}
                    {feesOpne == false ? (
                      <li>
                        <label>
                          <input
                            name="10000"
                            type="checkbox"
                            value="upto 10000"
                            checked={fees.includes("upto 10000")}
                            onChange={(e: any) => hendlClickfees(e)}
                          />
                          <i className="awe-icon awe-icon-check"></i>
                          upto 10000
                        </label>
                      </li>
                    ) : (
                      " "
                    )}
                    {feesOpne == false ? (
                      <li>
                        <label>
                          <input
                            name="1"
                            type="checkbox"
                            value="free"
                            checked={fees.includes("free")}
                            onChange={(e: any) => hendlClickfees(e)}
                          />
                          <i className="awe-icon awe-icon-check"></i>
                          free
                        </label>
                      </li>
                    ) : (
                      " "
                    )}
                  </ul>
                </div>
                {/* <!-- END / WIDGET -->
                            <!-- WIDGET --> */}
                <div className="widget widget_has_radio_checkbox">
                  <h5 onClick={hendleexperienceOpne}>
                    <span>
                      filter by <b>experience</b>{" "}
                      {experienceOpne == true ? (
                        <i className="fa fa-angle-up"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>{" "}
                  </h5>
                  <ul className={experienceOpne == true ? "countryHide" : ""}>
                    {experienceOpne == false ? (
                      <li>
                        <label>
                          <input
                            name="5"
                            type="checkbox"
                            value="upto 5 years"
                            checked={experience.includes("upto 5 years")}
                            onChange={(e: any) => hendlClickexperience(e)}
                          />
                          <i className="awe-icon awe-icon-check"></i>
                          upto 5 years
                        </label>
                      </li>
                    ) : (
                      " "
                    )}
                    {experienceOpne == false ? (
                      <li>
                        <label>
                          <input
                            name="10"
                            type="checkbox"
                            value="upto 10 years"
                            checked={experience.includes("upto 10 years")}
                            onChange={(e: any) => hendlClickexperience(e)}
                          />
                          <i className="awe-icon awe-icon-check"></i>
                          upto 10 years
                        </label>
                      </li>
                    ) : (
                      " "
                    )}
                    {experienceOpne == false ? (
                      <li>
                        <label>
                          <input
                            name="11"
                            type="checkbox"
                            value="above 10 years"
                            checked={experience.includes("above 10 years")}
                            onChange={(e: any) => hendlClickexperience(e)}
                          />
                          <i className="awe-icon awe-icon-check"></i>
                          above 10 years
                        </label>
                      </li>
                    ) : (
                      " "
                    )}
                  </ul>
                </div>
                {/* <!-- END / WIDGET -->
<!-- WIDGET --> */}
                <div className="widget widget_has_radio_checkbox">
                  <h5 onClick={hendlecountryOpne}>
                    <span>
                      filter by <b>country</b>{" "}
                      {countryOpne == true ? (
                        <i className="fa fa-angle-up"></i>
                      ) : (
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      )}
                    </span>{" "}
                  </h5>
                  <ul className={countryOpne == true ? "countryHide" : ""}>
                    {countryOpne == false &&
                      countrys &&
                      countrys?.map((data: any, i: any) => {
                        return (
                          <li key={i}>
                            <label>
                              <input
                                type="checkbox"
                                name={data?.id}
                                value={data?.country_name}
                                checked={
                                  country.includes(data?.country_name) ||
                                  countryid.includes(data?.id)
                                }
                                onChange={(e: any) => hendlClickCountry(e)}
                              />
                              <i className="awe-icon awe-icon-check"></i>
                              {data?.country_name}
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                {/* <!-- END / WIDGET --> */}
              </div>
              <div className="banner-section1">
                <VerticalAddSlider />
              </div>
            </div>
            <div className="col-lg-10 col-md-push-2">
              <div className="row">
                <div className="col-md-9 skew-tabs">
                  <ul>
                    <li
                      className={
                        counsellorandcoach == "counsellor" ? "active" : ""
                      }
                    >
                      <a
                        onClick={(e) => {
                          coun(e, { data: "counsellor" });
                        }}
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="35.03"
                            height="35.65"
                            viewBox="0 0 35.03 35.65"
                          >
                            <g id="advisory" transform="translate(0)">
                              <path
                                id="Path_6659"
                                data-name="Path 6659"
                                d="M32.189-511.58a9.332,9.332,0,0,0-7.63,6.125,9.979,9.979,0,0,0-.438,3.839,10.077,10.077,0,0,0,.54,4.181,10.768,10.768,0,0,0,2.75,4.32l.824.794v4l-.2.042c-.117.021-1.926.23-4.034.467-3.421.383-3.888.453-4.384.634a5.566,5.566,0,0,0-3.13,2.794c-.547,1.087-.54,1-.569,4.905l-.022,3.5H50.93l-.022-3.5c-.029-3.38-.036-3.519-.19-4.007a5.793,5.793,0,0,0-3.771-3.8c-.2-.062-2.057-.3-4.122-.536s-3.866-.439-3.99-.46l-.241-.035v-4l.824-.8a11.054,11.054,0,0,0,3.159-5.818,26.037,26.037,0,0,0,.007-5.33,9.167,9.167,0,0,0-7.2-7.184A13.642,13.642,0,0,0,32.189-511.58Zm3,2.258a6.987,6.987,0,0,1,5.077,4.815c.226.829.233.822-.226.669a7.8,7.8,0,0,1-3.027-1.93l-.671-.641-.678.662a11.66,11.66,0,0,1-1.123.969,9.939,9.939,0,0,1-3.954,1.428,12.615,12.615,0,0,1-3.494-.146c-.781-.188-.766-.146-.54-1a7.039,7.039,0,0,1,4.924-4.787,6.6,6.6,0,0,1,2.2-.244A8.112,8.112,0,0,1,35.188-509.322Zm1.692,6.25a13.42,13.42,0,0,0,3.006,1.4l.605.16v.92A6.776,6.776,0,0,1,40.1-498.1a8.7,8.7,0,0,1-4.683,5.115,5.684,5.684,0,0,1-5.114-.557,10.647,10.647,0,0,1-2.385-2.279,9.4,9.4,0,0,1-1.474-3.338,9.018,9.018,0,0,1-.1-1.421v-.906l.2.042a13.7,13.7,0,0,0,4.785.119,11.655,11.655,0,0,0,4.494-1.742,5.577,5.577,0,0,1,.511-.348A3.635,3.635,0,0,1,36.88-503.072Zm-5.632,12.229a8.229,8.229,0,0,0,3.334.237,13.378,13.378,0,0,0,1.736-.46c.066-.035.088.3.088,1.526v1.568l-1.466.773a13.239,13.239,0,0,1-1.554.759c-.044-.014-.729-.362-1.525-.773l-1.437-.759v-1.568c0-1.47.007-1.568.131-1.519C30.621-491.024,30.935-490.933,31.248-490.843Zm-.5,5.435,1.576.829v6.515H18.1v-2.111c0-2.362.058-2.766.452-3.47a3.7,3.7,0,0,1,1.525-1.435c.452-.209.613-.237,4.625-.7,2.291-.272,4.231-.481,4.319-.474S29.877-485.868,30.745-485.408Zm11.533-.348c3.888.439,4.027.46,4.479.676a3.585,3.585,0,0,1,1.9,2.446c.044.237.08,1.317.08,2.495v2.077H34.509v-6.515l1.627-.857c1.415-.746,1.649-.85,1.875-.815C38.149-486.23,40.075-486.007,42.278-485.756Z"
                                transform="translate(-15.9 511.623)"
                                fill="#acacac"
                              />
                              <path
                                id="Path_6660"
                                data-name="Path 6660"
                                d="M343-88.75v1.25h4.585V-90H343Z"
                                transform="translate(-319.579 118.148)"
                                fill="#acacac"
                              />
                            </g>
                          </svg>
                        </span>
                        counsellor
                      </a>
                    </li>
                    <li
                      className={counsellorandcoach == "coach" ? "active" : ""}
                    >
                      <a
                        onClick={(e) => {
                          coun(e, { data: "coach" });
                        }}
                      >
                        mentor{" "}
                        <span>
                          <svg
                            id="advisory"
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            height="35"
                            viewBox="0 0 35 35"
                          >
                            <path
                              id="Path_6659"
                              data-name="Path 6659"
                              d="M34.625-511.58a9.291,9.291,0,0,1,7.624,6.013,9.638,9.638,0,0,1,.437,3.769,9.735,9.735,0,0,1-.539,4.1,10.55,10.55,0,0,1-2.748,4.242l-.824.78v3.927l.2.041c.117.021,1.924.226,4.031.458a22.979,22.979,0,0,1,4.38.623,5.53,5.53,0,0,1,3.127,2.743c.547,1.067.539.985.569,4.816l.022,3.441h-35l.022-3.441c.029-3.318.036-3.455.189-3.934a5.738,5.738,0,0,1,3.768-3.729c.2-.061,2.055-.294,4.118-.527s3.863-.431,3.987-.452l.241-.034v-3.927l-.824-.787a10.8,10.8,0,0,1-3.156-5.712,25.12,25.12,0,0,1-.007-5.233,9.08,9.08,0,0,1,7.194-7.053A13.871,13.871,0,0,1,34.625-511.58Zm-3,2.216a6.929,6.929,0,0,0-5.073,4.727c-.226.814-.233.807.226.657a7.815,7.815,0,0,0,3.025-1.9l.671-.629.678.65a11.6,11.6,0,0,0,1.122.951,10.042,10.042,0,0,0,3.95,1.4,12.826,12.826,0,0,0,3.491-.144c.78-.185.765-.144.539-.978a6.976,6.976,0,0,0-4.92-4.7,6.707,6.707,0,0,0-2.194-.239A8.245,8.245,0,0,0,31.629-509.364Zm-1.691,6.136a13.519,13.519,0,0,1-3,1.375l-.6.157v.9a6.548,6.548,0,0,0,.394,2.442,8.606,8.606,0,0,0,4.679,5.021,5.765,5.765,0,0,0,5.109-.547,10.56,10.56,0,0,0,2.383-2.237,9.144,9.144,0,0,0,1.472-3.277,8.7,8.7,0,0,0,.1-1.4v-.889l-.2.041a13.931,13.931,0,0,1-4.781.116,11.766,11.766,0,0,1-4.49-1.71,5.589,5.589,0,0,0-.51-.342A3.653,3.653,0,0,0,29.938-503.228Zm5.627,12.006a8.363,8.363,0,0,1-3.331.233,13.563,13.563,0,0,1-1.735-.452c-.066-.034-.087.294-.087,1.5v1.539l1.465.759a13.322,13.322,0,0,0,1.552.746c.044-.014.729-.356,1.523-.759l1.436-.746v-1.539c0-1.444-.007-1.539-.131-1.491C36.192-491.4,35.878-491.311,35.565-491.222Zm.5,5.336-1.574.814v6.4H48.706v-2.073c0-2.319-.058-2.716-.452-3.407a3.67,3.67,0,0,0-1.523-1.409c-.452-.205-.612-.233-4.621-.691-2.289-.267-4.227-.472-4.315-.465S36.935-486.337,36.068-485.886Zm-11.523-.342c-3.885.431-4.023.451-4.475.664a3.529,3.529,0,0,0-1.9,2.4c-.044.233-.08,1.293-.08,2.449v2.039H32.307v-6.4l-1.625-.841c-1.414-.732-1.647-.835-1.873-.8C28.67-486.693,26.745-486.474,24.544-486.228Z"
                              transform="translate(-15.9 511.623)"
                              fill="#acacac"
                            />
                            <path
                              id="Path_6660"
                              data-name="Path 6660"
                              d="M347.4-88.8v1.2H343V-90h4.4Z"
                              transform="translate(-335.941 117.794)"
                              fill="#acacac"
                            />
                          </svg>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3">
                  <div className="page-top">
                    <select
                      className="awe-select"
                      style={{ width: "auto" }}
                      onChange={(e) => {
                        shortBy(e.target.value);
                      }}
                    >
                      <option>sort by</option>
                      <option value="certified">admissify certified</option>
                      <option value="verified">admissify verified</option>
                      <option value="premium">premium</option>
                      <option value="">online</option>
                      <option value="">maximum counselled</option>
                    </select>
                  </div>
                </div>
              </div>

              {counsellorandcoach == "counsellor" ? (
                <CounsellorList
                  available={available.filter((item) => item.is_visible === 1)}
                  fees={fees}
                  experience={experience}
                  country={country}
                />
              ) : (
                ""
              )}

              {counsellorandcoach == "coach" ? (
                <CoachList
                  available={available.filter((item) => item.is_visible === 1)}
                  fees={fees}
                  experience={experience}
                  country={country}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CounsellorAndCoach;
