import React, { useState, useEffect } from "react";
import Contactus from "./Contactus";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { URL } from "../../redux/common/url";
import { useSelector, useDispatch } from "react-redux";
import { officeAction } from "../../redux/common/action";
import ReCAPTCHAComponent from "./ReCAPTCHA";
import ContactForm from "./ContactForm";
import SocialShareButton from "../SocialShareButton";
import Seo from "../Seo/Seo";
import Breadcrumb from "../Breadcrumb";
import SeoHeaderContent from "../Seo/SeoHeaderContent";
import SeoFooterContent from "../Seo/SeoFooterContent";
import { onlyNameFilter } from "../../validation/Validation";
var other_details: any = [];
function Coach() {
  const urlsforShare = window.location.href;
  const history = useHistory();
  const dispatch = useDispatch();
  const baseurl = URL.API_BASE_URL;
  const [Scholarship, setScholarship] = useState<any>();
  const [resume, setResume] = useState<any>("");
  const [country, setcountry] = useState<any>({
    name: "",
    label: "",
    value: "",
  });
  const [intake, setintake] = useState<any>({
    name: "",
    label: "",
    value: "",
  });

  const countrys: any[] = useSelector((state: any) =>
    state.country.countryList ? state.country.countryList : []
  );
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const handleRecaptchaChange = (value: any) => {
    setRecaptchaValue(value);
  };

  const hendlereviews = (e: any) => {
    const { name, value } = e.target;
    setScholarship({
      ...Scholarship,
      [name]: value,
      other_details: other_details,
      service_name: "scholarship",
    });
  };

  const submitQuery = async (e: any) => {
    const Name = $("#Name").val();
    const Mobile = $("#Mobile").val();
    const Email = $("#Email").val();
    const City = $("#City").val();

    // if (!recaptchaValue) {
    //   alert("Please complete the reCAPTCHA!");
    //   return;
    // }

    const IsCountry = $("select[name=country]").val();
    const IsIntake = $("input[name=intake]").val();

    $(".error-class").remove();
    var isError = false;
    var phoneno = /^[1-9]{1}[0-9]{9}$/;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!Name) {
      $("#Name").after(
        '<div class="error-class">This field is required.</div><br />'
      );
      isError = true;
    }
    if (!Mobile) {
      $("#Mobile").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (Mobile) {
      if (Mobile.length != 10) {
        $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
        isError = true;
      }
    }
    if (Mobile) {
      if (phoneno.test(Mobile) === false) {
        $("#Mobile").after('<div class="error-class">Invalid mobile.</div>');
        isError = true;
      }
    }
    if (!Email) {
      $("#Email").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (Email) {
      if (regex.test(Email) === false) {
        $("#Email").after('<div class="error-class">Invalid email.</div>');
        isError = true;
      }
    }
    if (!City) {
      $("#City").after(
        '<div class="error-class">This field is required.</div>'
      );
      isError = true;
    }
    if (isError) {
      return false;
    }

    var InIntake = {
      name: "year",
      label: "tentative course intake",
      value: IsIntake ? IsIntake : "N/A",
    };
    var InCountry = {
      name: "country",
      label: "preferred country",
      value: IsCountry ? IsCountry : "N/A",
    };

    other_details.push(InCountry, InIntake);
    // return;
    dispatch(officeAction.submitQuery(Scholarship, history));
    $("#scholarshipIn").prop("disabled", true);
    // toast.success('Thanks for showing your interest, we will get back to you soon.');
  };

  const seo: any = useSelector((state: any) => state.setting.seo);
  //   useEffect(() => {
  //     window?.scrollTo(0, 0);
  //   });

  return (
    <>
      <Seo slug={window.location.pathname} />
      <div>
        <section className="tag-term">
          <Breadcrumb
            page={"Services"}
            data={{ slug: "Scholarship", path: "/services" }}
          />
          <div className="text-right pb-4 mr-3">
            <SocialShareButton urlsforShare={urlsforShare} data={""} />
          </div>
          <div className="headingmains text-center">
            <h2
              className="titlewithline aos-init aos-animate"
              data-aos="flip-left"
            >
              scholarship
              <span>
                <img
                  className="service-funding"
                  src="assets/img/ser01.png"
                  alt=""
                />
              </span>
            </h2>
          </div>
        </section>
        <section className="terms">
          <div className="container">
            {seo?.header_content && (
              <div className="about-uni-text shade1">
                <SeoHeaderContent />
              </div>
            )}
            <div className="row">
              <div className="terms-s  d-flex">
                <div className="col-lg-12">
                  <p>
                    You've determined to look overseas and find out the globe,
                    that's great! Let's start now with an inventory of
                    estimations so that you recognize wherein your cash got to
                    be spent. Our recommendation will assist you with withinside
                    the layout and control of your budgets without annoying you
                    in the least. And indeed, the straightforward fees, which
                    include accommodations, food, fitness, communication, and
                    journey, had been paid through us! Here's the value to think
                    about for Study Abroad The expected fees are calculated
                    through every faculty to line up the extent of the economic
                    necessity of a scholar. Total tuition, compulsory prices,
                    rooms, boards (wherein vital) and books, and different
                    dwelling fees (and once during a while journey expenses) are
                    calculated for school kids on the given faculty. Separate
                    determinations are often made at the discretion of the group
                    for school kids who occupy domestic or independently.
                    Funding sources Some colleges, public and private, offer
                    economic funding to school students. The bulk of the
                    institutional help presented to foreign places college
                    students in particular for undergraduate education
                    withinside the form of help and bursaries. As assist to
                    foreign places undergraduates is usually uncommon for few of
                    the universities, such bursaries are typically
                    extraordinarily aggressive. Please take care to check
                    together alongside your college to utilize any additions or
                    exemptions that they will give, the insight that every
                    personal and public university can waive software fees.
                    Special skills, abilities, or abilities are wont to supply
                    merit-primarily based totally scholarships. You’ll also get
                    TOEFL-primarily based scholarships, educational data,
                    innovative abilities, musical or athletic skills.
                    Merit-primarily based scholarships are regularly pretty
                    aggressive than want-primarily based scholarships. You've
                    got to show wonderful competence withinside the place vital
                    to be taken under consideration. Based on economic
                    necessities, scholarship funding is provided. Students with
                    a described stage who can display want could also be
                    eligible for this type of assist. University educational
                    departments might offer presents to help foreign places
                    college students with specific necessities and/or abilities.
                    There are distinctive kinds of supply funds. Here they're
                  </p>
                  <ul className="condition-list-1">
                    <li>Scholarships and presents </li>
                    <li>Private loans</li>
                    <li>Personal sources</li>
                  </ul>
                </div>
                {/* <div className="col-lg-4">
                           <ContactForm />
                        </div> */}
              </div>
              <div className="terms-s">
                <div className="postwrap">
                  <div className="container py-2">
                    <article className="postcard">
                      <img
                        className="postcard__img-new"
                        src="assets/img/scholarship_1.png"
                        alt=""
                      />
                      <div className="postcard__text postcard__text1">
                        <h4 className="pb-3 card-head1">
                          Study Abroad Scholarships
                        </h4>
                        <span className="border-bt"></span>
                        <div className="postcard__preview-txt ft-15">
                          Special skills, abilities, or abilities are wont to
                          supply merit-primarily based totally scholarships.
                          Merit-primarily based scholarships: Distinguished
                          college students line up with economic assistance thru
                          Merit-primarily based total scholarships to help them
                          attain their academic pursuits of an entire career.
                          Need-primarily based scholarships: Students with an
                          economic want line up with a bursary. These prizes are
                          based totally on family profits and choose
                          eligibility. Many bourses are best available to
                          residents and everlasting citizens for undergraduate
                          studies. However, there are unfastened studies and
                          studies databases for overseas undergraduate college
                          students additionally to non-public (loan), corporate,
                          non-profit, and authorities borrowing funds. Be
                          cautious of cheating studies gives: don't ship any
                          borrowing organization cash, financial organization
                          account details, or credit score card numbers.
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
                <div className="postwrap">
                  <div className="container py-2">
                    <article className="postcard postcard-n1">
                      <img
                        className="postcard__img-new"
                        src="assets/img/coaching_05.png"
                        alt=""
                      />
                      <div className="postcard__text postcard__text1">
                        <h4 className="pb-3 card-head1">why to pick us?</h4>
                        <span className="border-bt"></span>
                        <div className="postcard__preview-txt ft-15">
                          Our economic or scholarship steering consultancy place
                          gives you vital sources of overseas economic help.
                          However, many universities comprehend this and offer
                          reading bonds to admire your determination to look at.
                          We apprehend that global schooling overseas is often
                          costly. These fundings are intended to reduce the load
                          for aspiring college students. We'll assist you to
                          perceive and observe for bursaries conveniently. You'd
                          possibly keep your direction fees in many dollars.
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
                <div className="postwrap">
                  <div className="container py-2">
                    <article className="postcard">
                      <img
                        className="postcard__img-new"
                        src="assets/img/concul.png"
                        alt=""
                      />
                      <div className="postcard__text postcard__text1">
                        <h4 className="pb-3 card-head1">conclusion</h4>
                        <span className="border-bt"></span>
                        <div className="postcard__preview-txt ft-15">
                          Adjusting to a brand new country, culture, and
                          educational machine may be interesting and difficult.
                          Transitions may be specifically difficult whilst you
                          are a long way far from folks that normally assist
                          you. Counselors on the Admissify recognize this. We
                          are right here to assist college students to attain
                          their academic dreams without annoying about funding.
                          We recognize that issues on your non-public existence
                          can affect your capacity to learn. We, at Admissify,
                          recognize how difficult it could be to pursue your
                          research abroad. We offer more than a few Student
                          Essentials offerings to get rid of complexity and
                          strain making your global have a look at revel in
                          journey, a hassle-loose adventure. Need to assist?
                          Explore our offerings and depart us your information
                          withinside the inquiry and an Admissify counselor
                          might be assigned to manual thru each step of your
                          manner to stay and have a look at abroad.
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
            {seo?.footer_content && (
              <div className="about-uni-text shade1 mt-5">
                <SeoFooterContent />
              </div>
            )}
          </div>
        </section>
        <br />
        {/*   
     
     <Contactus /> */}

        <section className="categories section bg-grey" id="c-form">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-12">
                <div
                  style={{
                    visibility: "hidden",
                    position: "absolute",
                    width: "0px",
                    height: "0px",
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <symbol viewBox="0 0 24 24" id="expand-more">
                      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </symbol>
                    <symbol viewBox="0 0 24 24" id="close">
                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </symbol>
                  </svg>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="apply-bx" style={{ backgroundColor: "#fff" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-11 col-lg-10">
                        <h5 className="apply-n">apply now</h5>
                      </div>
                      <div className="col-md-12 col-lg-12">
                        <div className="fields-wrp">
                          <form id="scholarFrom">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="name"
                                    value={Scholarship?.name}
                                    id="Name"
                                    onChange={(e) => {
                                      hendlereviews(e);
                                    }}
                                    onInput={onlyNameFilter}
                                  />

                                  <label>name</label>
                                  <span className="fa fa-user p-2"></span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="mobile"
                                    maxLength={10}
                                    id="Mobile"
                                    value={Scholarship?.mobile}
                                    onChange={(e) => {
                                      hendlereviews(e);
                                    }}
                                  />
                                  <label>mobile</label>
                                  <span className="fa fa-phone p-2"></span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="email"
                                    id="Email"
                                    value={Scholarship?.email}
                                    onChange={(e) => {
                                      hendlereviews(e);
                                    }}
                                  />
                                  <label>email</label>
                                  <span className="fa fa-envelope p-2"></span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="field">
                                  <input
                                    autoComplete="new-off"
                                    type="text"
                                    name="city"
                                    id="City"
                                    value={Scholarship?.city}
                                    onChange={(e) => {
                                      hendlereviews(e);
                                    }}
                                  />
                                  <label>city</label>
                                  <span className="fa fa-map-marker p-2"></span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="field">
                                  <span className="fa fa-map-marker p-2"></span>
                                  <select
                                    className="form-control c-select"
                                    name="country"
                                    value={country?.country}
                                  >
                                    <option value="">preferred country</option>
                                    {countrys &&
                                      countrys?.map((data: any, i: any) => {
                                        return (
                                          <option
                                            key={i}
                                            value={data?.country_name}
                                          >
                                            {data?.country_name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="field">
                              <input
                                autoComplete="new-off"
                                type="text"
                                name="intake"
                                id="intake"
                                value={intake?.intake}
                              />
                              <label>tentative course intake</label>
                              <span className="fa fa-calendar p-2 r-20"></span>
                            </div>
                            <div className="field">
                              <textarea
                                autoComplete="new-off"
                                className="q-msg border__"
                                rows="4"
                                cols="50"
                                placeholder="message"
                                name="message"
                                value={Scholarship?.message}
                                onChange={(e) => {
                                  hendlereviews(e);
                                }}
                              ></textarea>
                            </div>
                            <br />
                            <br />
                            <br />

                            {/* <ReCAPTCHAComponent
                              onRecaptchaChange={handleRecaptchaChange}
                            /> */}

                            <div className="row">
                              <div className="col-md-12">
                                <div className="fieldbtn text-center">
                                  <input
                                    type="button"
                                    onClick={(e) => {
                                      submitQuery("scholarFrom");
                                    }}
                                    data-toggle="modal"
                                    id="scholarshipIn"
                                    className="bggreadent apply-sub-btn"
                                    value="submit"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Coach;
