import {
  LOADING,
  APPLICANTADD,
  GET_ALL_APLICANT,
  APPLY_NOW,
  RE_APPLY_NOW,
  GET_APPLICATION_LIST,
} from "../common/constant";
type stateType = {
  loading: boolean;
  applicatadd: any;
  getAllaplicant: any;
  applyNow: any;
  reapplyNow: any;
  getApplicationList: any;
};
const initialState: stateType = {
  loading: false,
  applicatadd: [],
  getAllaplicant: [],
  applyNow: [],
  reapplyNow: [],
  getApplicationList: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case APPLICANTADD:
      return { ...state, applicatadd: action.payload };
    case GET_ALL_APLICANT:
      return { ...state, getAllaplicant: action.payload };
    case APPLY_NOW:
      return { ...state, applyNow: action.payload };
    case RE_APPLY_NOW:
      return { ...state, reapplyNow: action.payload };
    case GET_APPLICATION_LIST:
      return { ...state, getApplicationList: action.payload };

    default:
      return state;
  }
};
