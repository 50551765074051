import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  studentAction,
  scholershipAndOfferAction,
} from "../../redux/common/action";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import dateFormat from "dateformat";
import { URL } from "../../redux/common/url";
import "../../assets/css/profile.css";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

function Application({ fileds }:any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modelData, setmodelData] = useState<any>("");

  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);

  const myAppointment: any = useSelector((state: any) =>
    state.student.myAppointment ? state.student.myAppointment : []
  );

  const baseurl = URL.API_BASE_URL;

  const getOFferAndScholar: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.getOFferAndScholar
      ? state.scholershipAndOffer.getOFferAndScholar
      : []
  );
  const opneModel = (e: any) => {
    const modeldata =
      getOFferAndScholar &&
      getOFferAndScholar?.filter((data: any) => {
        return data?.id == e;
      });

    setmodelData(modeldata);
  };
  const hendleApplyNow = (e: any) => {
    if (isAuthenticated == false) {
      history.push("/login", { logback: 1 });
    } else {
      history.push("/");
    }
  };

  $(".track-appli").click(function (k, v) {
    var id = $(this).attr("data-id");
    console.log(id);
    $("#track-applicants-" + id).fadeIn();
  });

  useEffect(() => {
    dispatch(
      studentAction.myAppointment({
        id: user?.id,
        mobile: user?.mobile,
      })
    );
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(scholershipAndOfferAction.getOffersAndScholar({}));
    return () => {};
  }, []);

  return (
    <div className="col-md-12 col-lg-9">
      <div className="row">
        <div className="col-md-5 col-lg-3 order-lg-3 tab_offer">
          <div className="page-sidebar">
            {getOFferAndScholar &&
              getOFferAndScholar?.map((data: any, i: any) => {
                return (
                  i < 2 && (
                    <div className="widget sidebar-title ads-blog">
                      <div key={i}>
                        <div className="offers-item_ yello title">
                          <a
                            onClick={(e) => {
                              data?.type == "Offers"
                                ? opneModel(data?.id)
                                : history.push(
                                    "/scholarships/" + data?.sch_slug
                                  );
                            }}
                            data-toggle="modal"
                            data-target="#offer-pop"
                          >
                            {data?.type == "Offers" ? (
                              data?.coupon_icon !== null ? (
                                <img
                                  className="scale"
                                  src={baseurl + data?.coupon_icon}
                                  onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src = "/assets/img/noImage.jpeg";
                                  }}
                                />
                              ) : (
                                <img
                                  className="scale"
                                  src="/assets/img/noImage.jpeg"
                                />
                              )
                            ) : data?.sch_image !== null ? (
                              <img
                                className="scale"
                                src={baseurl + data?.sch_image}
                                onError={(e: any) => {
                                  e.target.onerror = null;
                                  e.target.src = "/assets/img/noImage.jpeg";
                                }}
                              />
                            ) : (
                              <img
                                className="scale"
                                src="/assets/img/noImage.jpeg"
                              />
                            )}
                          </a>
                          <figcaption className="content-box offerHeight_">
                            <a
                              className="margin_auto"
                              onClick={(e) => {
                                data?.type == "Offers"
                                  ? opneModel(data?.id)
                                  : history.push(
                                      "/scholarships/" + data?.sch_slug
                                    );
                              }}
                              data-toggle="modal"
                              data-target="#offer-pop"
                            >
                              <h5>
                                {data?.type == "Offers" && (
                                  <span>
                                    {data?.coupon_type == 1
                                      ? "Get " + data?.max_off + "%"
                                      : "Get " + data?.max_off}{" "}
                                    OFF
                                  </span>
                                )}
                                {data?.type == "Offers"
                                  ? data?.coupon_title?.length > 16
                                    ? `${data?.coupon_title.substring(0, 15)}.`
                                    : data?.coupon_title
                                  : data?.sch_title?.length > 40
                                  ? `${data?.sch_title.substring(0, 40)}.`
                                  : data?.sch_title}
                              </h5>
                            </a>
                          </figcaption>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        </div>
        <div className="col-md-12 col-lg-9">
          <div className="contentbx shade1 bg-white application_wrap h-100">
            <section className="categories">
              <div className="col-md-12 col-lg-12">
                <div className="appli-t">
                  <h1>my appointments</h1>
                  <img src="/assets/img/appoint--icon.svg" />
                </div>
                {myAppointment.length == 0 ? (
                  <div className="col-lg-12 p-2">
                    <div className="appli-box shade1">
                      <div className="col-md-12 appli-box-t">
                        <p>
                          No appointment booked yet{" "}
                          <a
                            className="text_hyper"
                            onClick={() => {
                              history.push("/counsellor-and-mentor");
                            }}
                            id="DiscussionBtn"
                          >
                            book appointment
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    {myAppointment.length > 0 &&
                      myAppointment?.map((data: any, i: any) => {
                        let Date =
                          data?.Date && dateFormat(data?.Date, "dd-mm-yyyy");
                        return (
                          <div className="col-sm-12 col-md-6 col-lg-6">
                            <div
                              className="appoint _appointment_img"
                              style={{ marginTop: "20px" }}
                            >
                              <div className="row">
                                <div className="col-md-7">
                                  {data?.ReferenceType == 1 &&
                                  data?.CounsellorProfileImage != null ? (
                                    <img
                                      src={
                                        baseurl + data?.CounsellorProfileImage
                                      }
                                    />
                                  ) : data?.ReferenceType == 2 &&
                                    data?.CoachProfileImage != null ? (
                                    <img
                                      src={baseurl + data?.CoachProfileImage}
                                    />
                                  ) : (
                                    <img
                                      style={{ height: "30vh" }}
                                      src="/assets/img/noImage.jpeg"
                                      height="160px"
                                      width="100%"
                                    />
                                  )}
                                </div>

                                <div className="col-md-5 pl-0 other_info_p">
                                  <div className="other_info">
                                    <div>
                                      <h2>appointment id:</h2>
                                    </div>
                                    <div>
                                      <h1 className="c-d-gree">
                                        appt-{data?.Id}
                                      </h1>
                                    </div>

                                    <div>
                                      <h2>created date/time:</h2>
                                    </div>
                                    <div>
                                      <h1 className="c-d-gree">
                                        {data?.CreatedDate
                                          ? moment(data?.CreatedDate).format(
                                              "DD-MM-YYYY HH:mm"
                                            )
                                          : "N/A"}
                                      </h1>
                                    </div>

                                    <div>
                                      <h2>status:</h2>
                                    </div>
                                    <div>
                                      <h1 className="c-d-gree">
                                        {(() => {
                                          if (data?.Status == 1) {
                                            return (
                                              <span>appointment booked</span>
                                            );
                                          } else if (data?.Status == 2) {
                                            return (
                                              <span>request accepted</span>
                                            );
                                          } else if (data?.Status == 3) {
                                            return (
                                              <span>confirmation awaited</span>
                                            );
                                          } else if (data?.Status == 4) {
                                            return <span>completed</span>;
                                          } else if (data?.Status == 5) {
                                            return <span>cancelled</span>;
                                          } else if (data?.Status == 6) {
                                            return (
                                              <span>reschedule request</span>
                                            );
                                          } else if (data?.Status == 7) {
                                            return <span>refunded</span>;
                                          } else {
                                            return <span>N/A</span>;
                                          }
                                        })()}
                                      </h1>
                                    </div>

                                    <div>
                                      <h2> payment mode:</h2>
                                    </div>
                                    <div>
                                      <h1 className="c-d-gree">
                                        {data?.PaymentMode == 2
                                          ? "online"
                                          : "cash"}
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="couns-d">
                                <div className="row">
                                  <div className="col-8">
                                    <h1>
                                      {data?.ReferenceType == 1 &&
                                      data?.CounsellorName != null
                                        ? data?.CounsellorName
                                        : data?.ReferenceType == 2 &&
                                          data?.CoachName != null
                                        ? data?.CoachName
                                        : ""}
                                    </h1>
                                  </div>
                                  <div className="col-4">
                                    <h1 className="text-right">
                                      {data?.ReferenceType == 1 &&
                                      data?.CounsellorExperience != null
                                        ? data?.CounsellorExperience
                                        : data?.ReferenceType == 2 &&
                                          data?.CoachExperience != null
                                        ? data?.CoachExperience
                                        : "0"}{" "}
                                      Yr
                                    </h1>
                                  </div>
                                </div>
                                {data?.ReferenceType == 1
                                  ? "counsellor"
                                  : data?.ReferenceType == 2
                                  ? "mentor"
                                  : ""}
                                <p className="degi-1"></p>
                                <div className="row">
                                  <div className="col-6">
                                    <h2>appointment date:</h2>
                                  </div>
                                  <div className="col-6">
                                    <h1 className="text-right c-d-gree">
                                      {Date}
                                    </h1>
                                  </div>
                                  <div className="col-6">
                                    <h2>appointment time:</h2>
                                  </div>
                                  <div className="col-6">
                                    <h1 className="text-right c-d-gree">
                                      {data?.Time}
                                    </h1>
                                  </div>
                                </div>
                                {(() => {
                                  if (data?.Details) {
                                    return (
                                      <div className="all-center mt-4">
                                        <a
                                          href={data?.Details}
                                          rel="noreferrer"
                                          target="_blank"
                                          className="view-more readbtn op-l"
                                        >
                                          meeting link
                                        </a>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className="text-danger">
                                        Once link will be available, we will
                                        update you.
                                      </div>
                                    );
                                  }
                                })()}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
      <div
        id="offer-pop"
        className="modal fade offer_detailpopup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="off-detail-item">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <figure className="feat-text">
                  {modelData[0]?.type == "Offers" ? (
                    modelData[0]?.coupon_icon !== null ? (
                      <img
                        className="scale homeOffer"
                        src={baseurl + modelData[0]?.coupon_icon}
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src = "/assets/img/noImage.jpeg";
                        }}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img
                        className="scale homeOffer"
                        src="/assets/img/noImage.jpeg"
                      />
                    )
                  ) : modelData[0]?.sch_image !== null ? (
                    <img
                      className="scale homeOffer"
                      src={baseurl + modelData[0]?.sch_image}
                      onError={(e: any) => {
                        e.target.onerror = null;
                        e.target.src = "/assets/img/noImage.jpeg";
                      }}
                    />
                  ) : (
                    <img
                      className="scale homeOffer"
                      src="/assets/img/noImage.jpeg"
                    />
                  )}
                </figure>
                <figcaption className="content-box">
                  <h3>
                    {modelData[0]?.type == "Offers" ? "Get" : ""}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {modelData[0]?.coupon_type == 1
                          ? modelData[0]?.max_off + "%"
                          : modelData[0]?.max_off}{" "}
                        OFF
                      </span>
                    )}
                    {modelData[0]?.type == "Offers"
                      ? modelData[0]?.coupon_title?.length > 16
                        ? `${modelData[0]?.coupon_title.substring(0, 15)}.`
                        : modelData[0]?.coupon_title
                      : modelData[0]?.sch_title?.length > 40
                      ? `${modelData[0]?.sch_title.substring(0, 40)}.`
                      : modelData[0]?.sch_title}
                    {/* Study in Abroad */}
                  </h3>
                  <div className="bttns">
                    <a href="" className="tnc">
                      t&amp;c apply
                    </a>
                  </div>
                </figcaption>
                <figcaption className="details-box p-3">
                  <p className="text-left">
                    Coupon Code:{" "}
                    {modelData[0]?.type == "Offers" && (
                      <span>{modelData[0]?.coupon_code}</span>
                    )}{" "}
                  </p>
                  <p className="text-right">
                    exp:{" "}
                    {modelData[0]?.type == "Offers" && (
                      <span>
                        {moment(modelData[0]?.valid_from).format("DD-MM-YYYY")}
                      </span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.apply_end_date}.`
                      : ""}
                  </p>
                  <p className="text-left">
                    {/* {modelData[0]?.description} */}
                    {modelData[0]?.type == "Offers" && (
                      <span>{modelData[0]?.description}</span>
                    )}
                    {modelData[0]?.type == "ScholarShip"
                      ? `${modelData[0]?.short_desc}.`
                      : ""}
                  </p>
                  <div className="bttns text-right">
                    <a onClick={hendleApplyNow} className="applybtn bggreadent">
                      apply now
                    </a>
                  </div>
                </figcaption>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Application;
