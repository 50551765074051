import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import {
  LOADING,
  GET_ALL_UNI_COURSE,
  GET_ALL_COURSE,
  COUNTRY_COURSE,
  COUNTRY_EXAM,
  GET_COURSE,
  COURSE_DETAIL,
  GET_SIMILAR_COURSE
} from "../common/constant";

const loading = (data: any) => {
  return { type: LOADING, payload: data };
};
const getCountryCourseAction = (data: any) => {
  return { type: COUNTRY_COURSE, payload: data };
};
const getCourseDetailsAction = (data: any) => {
  return { type: COURSE_DETAIL, payload: data };
};
const getUniCourseAction = (data: any) => {
  return { type: GET_COURSE, payload: data };
};
const getCountryExamAction = (data: any) => {
  return { type: COUNTRY_EXAM, payload: data };
};
const getAllUniCoursesAction = (data: any) => {
  return { type: GET_ALL_UNI_COURSE, payload: data };
};
const getSimilarAction = (data: any) => {
  return { type: GET_SIMILAR_COURSE, payload: data };
};
const getAllCoursesAction = (data: any) => {
  return { type: GET_ALL_COURSE, payload: data };
};

export const getCourseDetails = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.post(`${URL.getCourseBySlug}`, data);
      dispatch(getCourseDetailsAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      // dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getAllCountryCourses = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.post(`${URL.countryCourse}`, data);
      dispatch(getCountryCourseAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getUniCourses = (data: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      const response: any = await api.post(`${URL.filterCourses}`, data);
      dispatch(getUniCourseAction(response?.data?.data));
      // dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getSimilarCourses = (data: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await api.post(`${URL.getSimilarcourse}`,data);
      dispatch(getSimilarAction(response?.data?.data));
    }
    catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getCountryExam = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(`${URL.examList}`);
      dispatch(getCountryExamAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getAllUniCourses = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.post(`${URL.getAllUniCourses}`, data);
      dispatch(getAllUniCoursesAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getAllCourses = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getAllCourses+'?status=1'}`);
      dispatch(getAllCoursesAction(response?.data?.data));
      dispatch(loading(false));
    }
    catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};