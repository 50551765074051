import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import {
  scholershipAndOfferAction,
  UniversityAction,
} from "../../redux/common/action";
import { URL } from "../../redux/common/url";
import Seo from "../Seo/Seo";
import dateFormat from "dateformat";
import { getToken } from "../../Utils/Auth/Token";
import jwt_decode from "jwt-decode";
import SocialShareButton from "../SocialShareButton";
import Breadcrumb from "../Breadcrumb";
function Scholarships() {
  const urlsforShare = window.location.href;
  const history = useHistory();
  const dispatch = useDispatch();
  const baseurl = URL.API_BASE_URL;
  const lastLocation = useLocation();

  const isAuthenticated: any = getToken();
  var user: any = isAuthenticated && jwt_decode(isAuthenticated);
  const scholarSlug: any = useParams();

  const [title, settitle] = useState<any>("scholarship-details");

  const findBySlugScholar: any = useSelector((state: any) =>
    state.scholershipAndOffer.findBySlugScholar
      ? state.scholershipAndOffer.findBySlugScholar
      : []
  );

  const getAllScholarship: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.getAllScholarship
      ? state.scholershipAndOffer.getAllScholarship
      : []
  );
  const appliedScholarData: any[] = useSelector((state: any) =>
    state.scholershipAndOffer.appliedScholarShip
      ? state.scholershipAndOffer.appliedScholarShip
      : []
  );

  const activeScholarShip =
    getAllScholarship &&
    getAllScholarship?.filter((data: any) => {
      return data?.status == 1;
    });

  const hadleDisableApply = (sch_id: any) => {
    let _dt = appliedScholarData.find((ele) => ele?.scholarship_id == sch_id);
    if (_dt) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    dispatch(scholershipAndOfferAction.scholarshipGetAllDetails({}));
    if (user?.id) {
      dispatch(
        scholershipAndOfferAction.getAppliedScholar({ student_id: user?.id })
      );
    }
    return () => {};
  }, []);

  function addInlineStylesToHtml(htmlString: any, styles: any) {
    // Create a temporary DOM element to hold the HTML string
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    // Function to recursively apply styles to all elements
    function applyStyles(element: any) {
      // Apply styles to the current element
      if (styles[element.tagName.toLowerCase()]) {
        element.style.cssText += styles[element.tagName.toLowerCase()];
      }

      // Recursively apply styles to child elements
      Array.from(element.children).forEach(applyStyles);
    }

    // Apply styles to the root element
    applyStyles(doc.body);

    // Serialize the modified DOM back to an HTML string
    return new XMLSerializer().serializeToString(doc.body);
  }

  const styles = {
    h1: "margin-top: 0.5em; margin-bottom: 0.5em;",
    p: "margin-top: 0.5em; margin-bottom: 0.5em;",
  };

  let deadline =
    findBySlugScholar?.apply_end_date &&
    dateFormat(findBySlugScholar?.apply_end_date, " dd-mmm-yyyy");

  const handleApplyNow = (e: any) => {
    if (isAuthenticated == false) {
      localStorage.setItem("lastLocation", lastLocation.pathname);
      history.push("/login", { logback: 1 });
    } else {
      let _dt = {
        student_id: user?.id,
        scholarship_id: findBySlugScholar.id,
      };
      dispatch(scholershipAndOfferAction.applyScholar(_dt));
    }
  };

  useEffect(() => {
    dispatch(scholershipAndOfferAction.fineByScholarSlug(scholarSlug?.slug));
    return () => {};
  }, [scholarSlug?.slug]);

  return (
    <div>
      <Seo
        slug={window.location.pathname}
        title={findBySlugScholar?.sch_title}
        meta_description={findBySlugScholar?.short_desc}
        meta_keywords={""}
        modified_time={
          findBySlugScholar?.updatedAt || findBySlugScholar?.createdAt
        }
        image={
          findBySlugScholar?.sch_image
            ? baseurl + findBySlugScholar?.sch_image
            : ""
        }
      />
      <section className="unifilter-page counsellor_details sugg-sholar">
        <div className="container">
          <Breadcrumb page={scholarSlug?.slug} data={{ slug: "", path: "" }} />
          <div className="row">
            <div className="col-lg-3 col-md-pull-10 order-lg-2">
              <div className="page-sidebar">
                <div className="widget counsellor shade2 ">
                  <h5 className="headgreenbg">
                    {" "}
                    suggested <b>scholarship</b>{" "}
                  </h5>
                  <ul className="bg-white">
                    {activeScholarShip &&
                      activeScholarShip?.map((data: any, i: any) => {
                        let suggesteddeadline =
                          data?.apply_end_date &&
                          dateFormat(data?.apply_end_date, " dd-mm-yyyy");
                        return (
                          <li
                            style={{
                              display:
                                findBySlugScholar?.sch_slug == data?.sch_slug
                                  ? "none"
                                  : "",
                            }}
                          >
                            <Link className="d-flex" to={`${data?.sch_slug}`}>
                              {data?.sch_icon !== null ? (
                                <img
                                  src={baseurl + data?.sch_icon}
                                  alt=""
                                  className="suggestedSholarship"
                                  onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src = "/assets/img/noImage.jpeg";
                                  }}
                                />
                              ) : (
                                <img src="/assets/img/logo_sholar.png" alt="" />
                              )}
                              <span>
                                <div className="counsellorName">
                                  <h6>
                                    {data?.sch_title && data?.sch_title}
                                    {/* gla university admission scholarship  */}
                                  </h6>
                                  <p>
                                    <b>deadline: </b>
                                    {suggesteddeadline}
                                    {/* 31-01-2021{" "} */}
                                  </p>
                                </div>
                              </span>
                            </Link>
                          </li>
                        );
                      })}

                    {/* <li>
                      <img src="/assets/img/logo_sholar.png" alt="" />
                      <div>
                        <h6>hdfc bank parivartan's ecs scholar....</h6>
                        <p>
                          <b>deadline: </b>31-01-2021{" "}
                        </p>
                      </div>
                    </li>
                    <li>
                      <img src="/assets/img/logo_sholar.png" alt="" />
                      <div>
                        <h6>gla university admission scholarship </h6>
                        <p>
                          <b>deadline: </b>31-01-2021{" "}
                        </p>
                      </div>
                    </li>
                    <li>
                      <img src="/assets/img/logo_sholar.png" alt="" />
                      <div>
                        <h6>hdfc bank parivartan's ecs scholar....</h6>
                        <p>
                          <b>deadline: </b>31-01-2021{" "}
                        </p>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-push-2">
              <div className="filter-page__content">
                <div className="filter-item-wrapper sholarship_details">
                  <div className="shade1">
                    <div className="details-top">
                      <div className="row">
                        <div
                          className="col-12 text-right mb-4 pt-3 pb-4"
                          style={{ paddingRight: "30px" }}
                        >
                          <SocialShareButton
                            urlsforShare={urlsforShare}
                            data={""}
                          />
                        </div>
                        <div className="col-md-12 col-lg-6 ">
                          <div
                            className="ScholarshipAboutimg"
                            style={{
                              background: `url(${
                                findBySlugScholar?.sch_image !== null
                                  ? baseurl + findBySlugScholar?.sch_image
                                  : "/assets/img/noImage.jpeg"
                              })`,
                            }}
                            onError={(e: any) => {
                              e.target.onerror = null;
                              e.target.style.background =
                                "/assets/img/noImage.jpeg";
                            }}
                          >
                            {/* {
  findBySlugScholar?.sch_image !== null ? 

                          <img
                            src={baseurl + findBySlugScholar?.sch_image} onError={(e:any)=>{e.target.onerror = null; e.target.src="/assets/img/noImage.jpeg"}}
                            className="scale"
                            alt=""
                          />:
                          <img
                            src="/assets/img/noImage.jpeg"
                            className="scale"
                            alt=""
                          />} */}
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                          <div className="topright">
                            <div className="row">
                              <div className="col-3">
                                {findBySlugScholar?.sch_icon && (
                                  <img
                                    className="__fouricon"
                                    src={
                                      baseurl +
                                      `/${findBySlugScholar?.sch_icon}`
                                    }
                                    alt=""
                                  />
                                )}
                              </div>
                              <div className="col-9">
                                <h5 className="__mr-10">
                                  {findBySlugScholar?.sch_title &&
                                    findBySlugScholar?.sch_title}
                                </h5>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <div className="fouricon">
                                  <img
                                    src="/assets/img/icons/sholar-icon01.jpg"
                                    alt=""
                                  />
                                  <p>
                                    <b>eligibility</b>{" "}
                                    <small className="eligibility_content">
                                      {findBySlugScholar?.eligibility &&
                                        findBySlugScholar?.eligibility}
                                      {/* pg applicants */}
                                    </small>
                                  </p>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="fouricon">
                                  <img
                                    src="/assets/img/icons/sholar-icon02.jpg"
                                    alt=""
                                  />
                                  <p>
                                    <b>region</b>{" "}
                                    <small>
                                      {findBySlugScholar?.Country != null
                                        ? findBySlugScholar?.Country
                                            .country_name
                                        : ""}
                                    </small>
                                  </p>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="fouricon">
                                  <img
                                    src="/assets/img/icons/sholar-icon03.jpg"
                                    alt=""
                                  />
                                  <p>
                                    <b>award</b>{" "}
                                    <small>
                                      {findBySlugScholar?.awards &&
                                        findBySlugScholar?.awards}
                                      {/* 50% fee reduction */}
                                    </small>
                                  </p>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="fouricon">
                                  <img
                                    src="/assets/img/icons/sholar-icon04.jpg"
                                    alt=""
                                  />
                                  <p>
                                    <b>deadline</b>{" "}
                                    <small>
                                      {deadline}
                                      {/* 05-aug-2021 */}
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="details-bottom">
                      <h5>about the program</h5>
                      <div
                        className="inner-html"
                        dangerouslySetInnerHTML={{
                          __html:
                            addInlineStylesToHtml(
                              findBySlugScholar?.description,
                              styles
                            ) || "",
                        }}
                      ></div>
                      <h5>eligibility</h5>
                      <ul>
                        <li>
                          {findBySlugScholar?.eligibility &&
                            findBySlugScholar?.eligibility}
                        </li>
                      </ul>
                      <h5>benefits</h5>
                      <ul className="list-styled">
                        <li>
                          {findBySlugScholar?.short_desc &&
                            findBySlugScholar?.short_desc}
                        </li>
                      </ul>
                      {/* <h5>documents</h5>
                      <ul className="list-styled">
                        <li>admission receipt</li>
                        <li>admission receipt</li>
                      </ul> */}
                      <div className="all-center mt-3">
                        <a
                          onClick={handleApplyNow}
                          className={
                            hadleDisableApply(findBySlugScholar?.id)
                              ? "view-more readbtn disableApply"
                              : "view-more readbtn"
                          }
                        >
                          {hadleDisableApply(findBySlugScholar?.id)
                            ? "Applied"
                            : "Apply Now"}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Scholarships;
